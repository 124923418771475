<template>
  <v-row class="scroll-form-row flex-nowrap">
    <v-col align-self="start">
      <v-combobox
        :id="`sdk-key-combobox-${index}`"
        :label="$t('configuration.parameter-name')"
        :items="filteredKeySuggestions"
        :rules="[rules.required, (val) => isKeyValid(val), () => isKeyUnique()]"
        outlined
        dense
        append-icon=""
        :value="rowKey"
        hide-details="auto"
        :disabled="isReadOnly"
        @input.native="(e) => $emit('update:rowKey', e.target.value)"
        @change="(val) => $emit('update:rowKey', val)"
      >
        <template #append>
          <PtrIcon
            v-if="rowKey && rowKey.length"
            icon="cancel-circle"
            style="cursor: pointer"
            @click.native="() => $emit('update:rowKey', '')"
          />
        </template>
      </v-combobox>
    </v-col>
    <v-col cols="auto" align-self="start">
      <v-checkbox
        v-if="rowValueType === 'Boolean'"
        :id="`sdk-value-checkbox-${index}`"
        :input-value="typeof rowValue === 'string' ? rowValue.toLowerCase() === 'true' : rowValue"
        class="checkbox"
        outlined
        hide-details
        :disabled="isReadOnly"
        @change="(val) => $emit('update:rowValue', !!val)"
      />
      <v-text-field
        v-else
        :id="`sdk-value-text-${index}`"
        :value="rowValue"
        :label="$t('configuration.value')"
        :rules="[rules.required, isValueValid]"
        dense
        outlined
        hide-details="auto"
        :disabled="isReadOnly"
        @input="(val) => $emit('update:rowValue', val)"
      >
        <template #append>
          <PtrIcon
            icon="cancel-circle"
            style="cursor: pointer"
            :style="{ visibility: rowValue && rowValue.length ? 'visible' : 'hidden' }"
            @click.native="() => $emit('update:rowValue', '')"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col class="col-sm-3 col-md-2" align-self="start">
      <v-select
        :id="`sdk-type-select-${index}`"
        :value="rowValueType"
        :label="$t('configuration.type')"
        :rules="[rules.required]"
        :disabled="isDisabled || isReadOnly"
        dense
        outlined
        :items="dataTypes"
        hide-details
        hide-selected
        @change="(val) => $emit('update:rowValueType', val)"
      >
        <!-- This is for selection -->
        <template #prepend-item="{ item }"><span :id="`sdk-type-data-types-${item}`"></span></template>
      </v-select>
    </v-col>
    <v-col cols="auto" align-self="start">
      <v-btn
        :id="`sdk-row-delete-${index}`"
        small
        color="error"
        class="delete-button mr-4"
        :disabled="isReadOnly"
        @click="() => $emit('deleted')"
      >
        {{ $t("delete") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ValidationHelpers from "@/helpers/ValidationHelpers";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import ValueDataTypeHelper from "@/constants/WebConfigurationParameterValueDataType";

export default {
  name: "SdkConfigRowElem",
  components: { PtrIcon },
  props: {
    keySuggestions: { type: Array, default: () => [] },
    rowKey: String,
    rowValue: [Number, String, Boolean],
    rowValueType: [Number, String],
    isDisabled: Boolean,
    isReadOnly: { type: Boolean, default: false },
    draftConfig: { type: Array, default: () => [] },
    index: Number
  },
  data: () => ({
    rules: {
      required: (value) => ValidationHelpers.isRequired(value)
    }
  }),

  computed: {
    dataTypes() {
      return ValueDataTypeHelper.VALUE_DATA_TYPES.map((typeObj) => typeObj.label);
    },
    filteredKeySuggestions() {
      if (!this.rowKey) {
        return this.keySuggestions;
      }
      return this.keySuggestions.filter((keySuggestion) =>
        keySuggestion.toLowerCase().includes(this.rowKey.toLowerCase())
      );
    },
    isValueValid() {
      return (
        ValidationHelpers.checkByType(this.rowValue, this.rowValueType) ||
        this.$t("invalid.invalid-value", { name: this.$t("configuration.configuration-value") })
      );
    }
  },

  methods: {
    isKeyValid(value) {
      return (
        ValidationHelpers.isKeyValid({ key: value, min: 8, max: 100 }) ||
        this.$t("invalid.invalid-key", { name: this.$t("configuration.configuration-key"), min: 8, max: 100 })
      );
    },
    isKeyUnique() {
      return (
        this.draftConfig.filter((config) => config.configurationKey === this.rowKey).length === 1 ||
        this.$t("contents.advancedSdkConfigs.duplicate-configuration")
      );
    }
  }
};
</script>

<style>
.sdk-config-row-site-building {
  margin-left: -11px !important;
}
.sdk-config-row-site-building .col {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.scroll-form-row .v-input {
  font-size: 15px;
}
</style>
