<template>
  <v-overlay :dark="$vuetify.theme.dark" z-index="1000">
    <v-card class="px-4 advanced-card">
      <v-container id="advanced-configs" fluid>
        <v-row class="advanced-sdk-config-row flex-nowrap">
          <v-col cols="auto" class="flex-grow-1 flex-shrink-1">
            <v-card-title id="advanced-config-title" class="px-0 text-h5">
              {{ $t("contents.config-title") }}
            </v-card-title>
            <v-card-subtitle id="advanced-config-subtitle" class="px-0">
              {{ $t(`${translationPath}config-subtitle`) }}
            </v-card-subtitle>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-row class="advanced-sdk-config-row" no-gutters>
              <v-col>
                <v-btn id="advanced-config-discard" class="mr-2" outlined @click.stop="onDiscard">
                  {{ $t("discard") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  id="advanced-config-confirm"
                  color="primary"
                  :disabled="(isDisabled || !isAdvancedConfigFormDirty) && !isDeleteActivated"
                  @click="confirm"
                >
                  {{ $t("confirm") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row id="form-wrapper" class="advanced-sdk-config-row" no-gutters>
          <v-col>
            <div class="scroll-form">
              <v-form
                v-if="!$route.name.includes('Level')"
                ref="configForm"
                v-model="sdkConfigsValid"
                name="config-form"
              >
                <v-row class="advanced-sdk-config-row">
                  <v-col class="title" cols="auto">{{ $t(`${translationPath}sdk`) }}</v-col>
                  <v-col>
                    <v-btn
                      id="advanced-config-add-new-sdk"
                      color="neutral"
                      small
                      outlined
                      :disabled="isAddConfigRowDisabled || isReadOnly"
                      @click="addConfigRow"
                    >
                      {{ $t("addNew") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!draftSdkConfigurations.length" class="advanced-sdk-config-row">
                  <v-col cols="12">
                    <v-alert color="#f5f8fe">
                      <p class="alert">
                        <strong>{{ $t(`${translationPath}no-custom-params`) }}</strong>
                      </p>
                    </v-alert>
                  </v-col>
                </v-row>
                <SdkConfigRowElem
                  v-for="(config, i) in draftSdkConfigurations"
                  :key="config.id"
                  :index="i"
                  class="sdk-config-row-site-building"
                  :draft-config="draftSdkConfigurations"
                  :key-suggestions="keySuggestions"
                  :is-disabled="isDisabledMap[config.id]"
                  :is-read-only="isReadOnly"
                  :row-key.sync="draftSdkConfigurations[i].configurationKey"
                  :row-value.sync="draftSdkConfigurations[i].configurationValue"
                  :row-value-type.sync="draftSdkConfigurations[i].configurationValueType"
                  @deleted="deleteSdkConfigRow(i)"
                  @update:rowKey="(val) => handleChange(i, val)"
                  @update:rowValueType="() => handleTypeChange(i)"
                  @update:rowValue="() => (isAdvancedConfigFormDirty = true)"
                />
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";
import Helpers from "@/helpers/Helpers";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ValueDataTypeHelper from "@/constants/WebConfigurationParameterValueDataType";
import SdkConfigurationService from "@/services/SdkConfigurationService";
import SdkConfigRowElem from "@/components/configuration/SdkConfigRowElem.vue";

export default {
  name: "AdvancedSdkConfigs",
  components: { SdkConfigRowElem },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    translationPath: "contents.advancedSdkConfigs.",
    rules: {
      required: (value) => ValidationHelpers.isRequired(value)
    },
    isEdit: false,
    isDeleteActivated: false,
    draftSdkConfigurations: [],
    isDisabledMap: {},
    defaultSdkConfigs: [],
    defaultRowData: {
      id: "",
      configurationKey: "",
      configurationValue: "",
      configurationValueType: "String"
    },
    sdkConfigsValid: false,
    isAdvancedConfigFormDirty: false
  }),

  computed: {
    ...mapState("ADVANCED_CONFIGS", ["initialSdkConfigurations", "cachedSdkConfigurations"]),
    keySuggestions() {
      return this.defaultSdkConfigs.map((config) => config.configurationKey);
    },
    isDisabled() {
      return !this.$route.name.includes("Level") && !this.sdkConfigsValid;
    },
    isAddConfigRowDisabled() {
      return this.draftSdkConfigurations.length > 0 && !this.sdkConfigsValid;
    }
  },

  watch: {
    cachedSdkConfigurations: {
      immediate: true,
      handler() {
        this.draftSdkConfigurations = JSON.parse(JSON.stringify(this.cachedSdkConfigurations));
        // generate ids for drafted configs that has no id
        this.draftSdkConfigurations.forEach((config) => {
          if (!config.id) {
            config.id = Helpers.generateUuid();
          }
        });
        this.updateDisabledTypes();
      }
    },
    draftSdkConfigurations() {
      this.isAdvancedConfigFormDirty = true;
    }
  },

  created() {
    this.initialize();

    if (!this.$route.path.includes("new")) {
      this.isEdit = true;
    }
  },

  methods: {
    async initialize() {
      this.isDeleteActivated = false;
      try {
        this.defaultSdkConfigs = await SdkConfigurationService.getDefaultSdkConfigurations();
        this.updateDisabledTypes();
      } catch (error) {
        console.debug(error);
      }
    },
    addConfigRow() {
      this.draftSdkConfigurations = [
        { ...this.defaultRowData, id: Helpers.generateUuid() },
        ...this.draftSdkConfigurations
      ];
    },
    async confirm() {
      if (this.isDeleteActivated) {
        this.$emit("deleteAction");
        return;
      }
      this.$store.dispatch("ADVANCED_CONFIGS/SET_CACHED_SDK_CONFIGURATIONS", {
        configs: this.draftSdkConfigurations
      });
      this.isFormDirty = false;
      this.$emit("discard", this.isFormDirty);
    },
    toggleDelete() {
      this.isDeleteActivated = !this.isDeleteActivated;
    },
    goBack() {
      const currentPath = this.$router.history.current.path;
      this.$router.push(currentPath.substring(0, currentPath.lastIndexOf("/"))).catch((e) => console.log(e.message));
    },
    handleChange(index, key) {
      const configToChange = this.draftSdkConfigurations[index];
      this.isDisabledMap[configToChange.id] = this.keySuggestions.includes(key);
      if (this.isDisabledMap[configToChange.id]) {
        const keySuggestionObj = this.defaultSdkConfigs.find((config) => config.configurationKey === key);
        configToChange.configurationValue = keySuggestionObj.configurationValue;
        configToChange.configurationValueType = keySuggestionObj.configurationValueType;
      }
      this.$refs.configForm.validate();
      this.isAdvancedConfigFormDirty = true;
    },
    handleTypeChange(index) {
      const configToChange = { ...this.draftSdkConfigurations[index] };
      const valueDataTypeObj = ValueDataTypeHelper.VALUE_DATA_TYPES.find(
        (dataType) => dataType.label === configToChange.configurationValueType
      );
      configToChange.configurationValue = valueDataTypeObj.default;
      const clone = [...this.draftSdkConfigurations];
      clone[index] = configToChange;
      this.draftSdkConfigurations = clone;
      this.isAdvancedConfigFormDirty = true;
    },
    updateDisabledTypes() {
      this.draftSdkConfigurations.forEach((config) => {
        const shouldDisable = this.defaultSdkConfigs.some(
          (defaultConfig) => defaultConfig.configurationKey === config.configurationKey
        );
        if (shouldDisable) {
          this.isDisabledMap[config.id] = shouldDisable;
        }
      });
    },
    deleteSdkConfigRow(i) {
      this.draftSdkConfigurations.splice(i, 1);
      this.$refs.configForm.validate();
    },
    onDiscard() {
      this.$emit("discard", this.isFormDirty);
    }
  }
};
</script>
<style scoped>
#advanced-config-title,
#advanced-config-subtitle {
  word-break: break-word;
}

#advanced-config-add-new-sdk {
  margin-left: 24px;
}
</style>
