<template>
  <v-select
    v-model="selectedPrice"
    :items="options"
    item-text="text"
    item-value="value"
    :label="$t(`${translationPath}price`)"
    dense
    style="z-index: 99"
    hide-details="auto"
    append-icon=""
    outlined
    :rules="[rules.required]"
    :disabled="isReadOnly"
    @change="setFormDirty"
  >
    <template #append>
      <PtrIcon icon="caret-down" />
    </template>
    <template slot="selection" slot-scope="data">
      <!-- HTML that describe how select should render selected items -->
      <div>
        <span>{{ data.item.text }}</span>
        <span v-if="data.item.text.length < maxPrice">
          <span v-for="n in maxPrice - data.item.text.length" :key="n" class="lighter-text">$</span>
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import ValidationHelpers from "@/helpers/ValidationHelpers";

export default {
  components: { PtrIcon },
  props: {
    value: [Number, Object],
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    selectedPrice: undefined,
    priceSign: "$",
    maxPrice: 4,
    options: [
      { text: "$", value: 1 },
      { text: "$$", value: 2 },
      { text: "$$$", value: 3 },
      { text: "$$$$", value: 4 }
    ],
    rules: {
      required: (value) => ValidationHelpers.isRequired(value)
    }
  }),

  watch: {
    value() {
      this.selectedPrice = this.value?.price || this.value;
    },
    selectedPrice() {
      this.$emit("input", { priceSign: "$", priceMax: 4, price: this.selectedPrice });
    }
  },
  created() {
    this.selectedPrice = this.value?.price || this.value;
  },
  methods: {
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-select__selections {
    * {
      color: var(--v-neutral-darken3);
      font-size: #{$font-size-2};
    }
  }

  .v-list-item__title {
    color: var(--v-neutral-darken3);
    font-size: #{$font-size-2} !important;
  }
}

.lighter-text {
  color: var(--v-neutral-lighten1);
}

::v-deep fieldset {
  border-radius: 8px;
  border: 2px solid var(--v-scrollbarGray-base) !important;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}
</style>
