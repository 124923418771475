<template>
  <div class="poi-property-input-container py-1 px-2">
    <v-row class="d-flex align-center justify-space-between flex-nowrap mt-0 mb-2">
      <v-col class="property-header py-0">{{ $t(`${translationPath}imageUrlTitle`, { type: name }) }}</v-col>
      <v-col v-if="maxCount" class="property-count-info property-header py-0">{{ countInfoText }}</v-col>
    </v-row>
    <v-row class="my-0"></v-row>
    <v-row class="mt-0" :class="shouldDisplayInput ? 'mb-2' : 'mb-0'">
      <v-col class="my-0 py-0">
        <div v-for="(url, index) in urlList" :key="index" class="url-item d-flex justify-space-between mb-1">
          <div class="d-flex name-container">
            <img :src="url" class="thumbnail" :class="name" alt="thumbnail" />
            <div class="url">{{ url }}</div>
          </div>

          <PtrIcon
            v-if="value?.length && !isReadOnly"
            class="mr-2"
            icon="close"
            width="12"
            height="12"
            style="cursor: pointer"
            @click.native="removeUrl(index)"
          />
        </div>
        <v-text-field
          v-if="shouldDisplayInput"
          ref="textField"
          v-model="inputUrl"
          :placeholder="$t(`${translationPath}enterUrl`)"
          :rules="[rules.required, rules.isImageUrlValid]"
          hide-details="auto"
          outlined
          dense
          :disabled="isReadOnly"
          @keydown.enter.prevent="onSubmit"
          @blur="onSubmit"
        >
          <template #append>
            <PtrIcon
              v-if="value?.length && !isReadOnly"
              class="mr-n2"
              icon="cancel-circle"
              width="18"
              height="18"
              style="cursor: pointer"
              @click.native="resetInput"
            />
          </template>
        </v-text-field>
        <div
          v-else-if="shouldDisplayAddNew && !isReadOnly"
          class="add-new-image d-flex flex-start my-1"
          @click="onAddNewClicked"
        >
          <PtrIcon icon="plus" width="12" height="12" style="cursor: pointer" color="#346df1" editable-color />
          <div>{{ $t(`${translationPath}addAnotherImage`) }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="restriction && shouldDisplayInput" class="restriction paragraph-xs mt-3 mb-2">{{ restriction }}</v-row>
    <v-row v-if="shouldDisplayInput" class="property-note my-0"
      ><span class="paragraph-xs"
        >{{ $t(`${translationPath}disclaimer`) }} <span>{{ $t(`${translationPath}disclaimerDescription`) }}</span></span
      >
    </v-row>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import ValidationHelpers from "@/helpers/ValidationHelpers";

export default {
  components: { PtrIcon },
  props: {
    value: Array,
    maxCount: Number,
    restriction: String,
    name: String,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.feature.",
    urlList: undefined,
    inputUrl: "",
    isAddNewClicked: false
  }),
  computed: {
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value),
        isImageUrlValid: (value) => {
          if (value === undefined || value === "") {
            return this.$t("contents.mapDesigner.propertyRequired", { propertyName: "Image url" });
          }
          return (
            ValidationHelpers.isImageUrlTypeValid(value) ||
            this.$t("contents.mapDesigner.imageNotValid", { propertyName: "Image url" })
          );
        }
      };
    },
    countInfoText() {
      const urlListLength = this.urlList ? this.urlList.length : 0;
      const imagesText = this.$t(`${this.translationPath}images`);
      return `${urlListLength}/${this.maxCount} ${imagesText}`;
    },
    isMultiple() {
      return this.maxCount > 1;
    },
    shouldDisplayInput() {
      return this.urlList.length === 0 || (this.urlList.length < this.maxCount && this.isAddNewClicked);
    },
    shouldDisplayAddNew() {
      return this.urlList.length > 0 && this.urlList.length < this.maxCount && !this.isAddNewClicked;
    }
  },
  watch: {
    value() {
      this.urlList = this.value;
    }
  },
  created() {
    this.urlList = this.value;
  },
  methods: {
    removeUrl(index) {
      if (this.name === "logo") {
        this.$emit("clear");
      } else {
        const temp = this.urlList;
        temp.splice(index, 1);
        this.urlList = [...temp];
        this.$emit("input", this.urlList);
        this.$emit("remove", index);
      }
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    onSubmit() {
      if (!this.$refs.textField.validate()) {
        // Input is invalid, return early
        return;
      }
      const temp = this.urlList;
      temp.push(this.inputUrl);
      this.urlList = [...temp];
      this.resetInput();
      this.$emit("input", this.urlList);
      this.isAddNewClicked = false;
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    resetInput() {
      if (this.inputUrl === "") {
        this.isAddNewClicked = false;
      }
      this.inputUrl = "";
    },
    onAddNewClicked() {
      this.isAddNewClicked = true;
    },
    /**
     * Updates UrlList when the user clicks save
     * So that if the user enters a url as input but not pressed enter afterwards it is still added to urlList & saved
     */
    update() {
      if (this.inputUrl !== "" && this.urlList.findIndex((url) => url === this.inputUrl) === -1) {
        this.onSubmit();
      }
    }
  }
};
</script>
<style lang="scss">
.property-count-info {
  text-align: right;
}

.property-note {
  > span {
    color: var(--v-neutral-base);
    font-weight: #{$font-weight-semi-bold};

    span {
      font-weight: 400;
    }
  }
}

.url-item {
  width: 100%;
  padding: 4px;
  align-content: center;
  gap: #{$spacing-m};
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--v-neutral-lighten3);
  background: var(--v-white-base);

  .thumbnail {
    width: 48px;
    height: 27px;
    border-radius: 4px;

    &.logo {
      width: 27px;
    }
  }

  .url {
    overflow: hidden;
    color: var(--v-neutral-base);
    text-overflow: ellipsis;
    max-width: 120px;
    font-size: #{$font-size-2};
    white-space: nowrap;
    line-height: #{$line-height-10}; /* 200% */
  }
}

.name-container {
  gap: #{$spacing-m};
}

.add-new-image {
  border-radius: 4px;
  border: 1px solid var(--v-primary-lighten3);
  background-color: var(--v-primary-lighten5);
  gap: #{$spacing-m};
  padding: 4px;
  color: var(--v-primary-base);
  font-size: #{$font-size-2};
  line-height: #{$line-height-10}; /* 200% */
  cursor: pointer;
}
</style>
