<template>
  <div class="ui-wrapper">
    <div class="ui">
      <div class="left-ui">
        <div class="left-top-ui">
          <div class="towards-right">
            <slot name="topLeftTowardsRight"></slot>
          </div>
          <div class="towards-down">
            <slot name="topLeftTowardsDown"></slot>
          </div>
        </div>
        <div class="left-bottom-ui">
          <div class="towards-up">
            <slot name="bottomLeftTowardsUp"></slot>
          </div>
          <div class="towards-right">
            <slot name="bottomLeftTowardsRight"></slot>
          </div>
        </div>
      </div>
      <div class="center-ui">
        <div class="center-top-ui">
          <slot name="centerTop"></slot>
        </div>
      </div>
      <div class="center-bottom-ui">
        <slot name="centerBottom"></slot>
      </div>
      <div class="right-ui">
        <div class="right-top-ui">
          <div class="towards-left">
            <slot name="topRightTowardsLeft"></slot>
          </div>
          <div class="towards-down">
            <slot name="topRightTowardsDown"></slot>
          </div>
        </div>
        <div class="right-bottom-ui">
          <div class="towards-left">
            <slot name="bottomRightTowardsLeft"></slot>
          </div>
          <div class="towards-up">
            <slot name="bottomRightTowardsUp"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapUI"
};
</script>

<style lang="scss">
.v-application .ui-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: margin 0.4s linear;
  .ui {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 24px;

    .v-btn {
      pointer-events: auto;
      user-select: none;
      box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16) !important;
      padding: 0 !important;

      &.select-btn {
        box-shadow: none !important;

        &:hover {
          box-shadow: none !important;
        }
      }

      &:hover {
        box-shadow: 0px 4px 8px #17191c52 !important;
      }
      &.active {
        background: var(--v-primary-lighten3);
      }
      &.disabled {
        color: var(--v-neutral-lighten2);
        background: var(--v-neutral-lighten3);
      }
    }

    .v-btn--disabled.v-btn--has-bg {
      background-color: var(--v-neutral-lighten3) !important;
      color: var(--v-neutral-darken1) !important;
    }

    .left-ui {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 60px;
      transition: margin 0.4s linear;
      .left-top-ui {
        display: flex;
        flex-direction: column;

        .towards-down {
          display: flex;
          flex-direction: column;

          &:not(:empty) {
            margin-right: 16px;
          }

          .v-btn:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        .towards-right {
          display: flex;

          &:not(:empty) {
            margin-bottom: 12px;
          }

          .v-btn:not(:last-child) {
            margin-right: 16px;
          }
        }
      }

      .left-bottom-ui {
        .towards-up {
          .v-btn:not(:first-child) {
            margin-top: 8px;
          }
        }

        .towards-right {
          display: flex;

          &:not(:empty) {
            margin-top: 24px;
          }

          .v-btn:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }

    .center-ui {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-width: 60px;

      .v-btn {
        margin: 0 0 16px 16px;
      }
    }
    .center-bottom-ui {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .right-ui {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 60px;
      position: fixed;
      bottom: 24px;
      right: 24px;
      top: 20px;

      .right-top-ui {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: flex-end;

        .towards-left {
          display: flex;

          &:not(:empty) {
            margin-bottom: 24px;
          }

          .v-btn:not(:first-child) {
            margin-left: 16px;
          }
        }

        .towards-down {
          .v-btn:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }

      .right-bottom-ui {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
        overflow: hidden;
        margin-right: -8px;
        margin-bottom: -16px;

        .towards-up {
          display: flex;
          flex-direction: column;
          height: 100%;

          &:not(:empty) {
            margin-left: 24px;
            padding: 16px 0;
          }

          .v-btn:not(:first-child) {
            margin-top: 8px;
          }
        }

        .towards-left {
          display: flex;

          .v-btn:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.theme--dark {
  .ui-wrapper {
    .v-btn {
      background: var(--v-neutral-darken4) !important;

      &:hover {
        box-shadow: 0px 4px 8px #17191cc0 !important;
      }
    }
  }
}
</style>
