<template>
  <v-menu
    v-if="breadcrumbText"
    v-model="isActive"
    :disabled="isDisabled"
    offset-y
    content-class="breadcrumb-wrapper ptr-tooltip-menu tooltip-top"
    min-width="240"
  >
    <template #activator="{ on, attrs }">
      <v-col
        v-show="breadcrumbText"
        class="breadcrumb paragraph-strong text-no-wrap d-flex align-center py-2"
        :class="{
          'active-breadcrumb': isActive,
          'disabled-breadcrumb': isDisabled,
          'ml-n2': shouldHideBackButton,
          'ml-n4': !shouldHideBackButton
        }"
        v-bind="attrs"
        v-on="on"
      >
        <div class="clickable">{{ breadcrumbText }}</div>

        <PtrIcon v-show="breadcrumbText" icon="caret-down" editable-color width="12" class="caret-down" />
      </v-col>
    </template>

    <v-card v-if="breadcrumbList.length" class="tooltip-menu-card">
      <v-list dense class="tooltip-menu-content">
        <v-list-item class="tooltip-header pl-2">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-btn color="white" class="v-btn--square mr-2" small icon depressed plain @click="onHomeButtonClicked">
                <PtrIcon icon="home" width="28" height="28" />
              </v-btn>
            </v-col>
            <v-col v-if="breadcrumbList[0].parentTitle">
              <v-list-item-title class="tooltip-menu-title">
                <strong>{{ breadcrumbList[0].parentTitle }}</strong>
              </v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="({ title, route, disable }, index) in breadcrumbList"
          :key="index"
          :to="route"
          active-class="active-breadcrumb-item"
          dense
          class="breadcrumb-item flat-list-item"
          :disabled="disable"
          @click="handleRouteChange(index)"
        >
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon";
import PointMode from "@/helpers/drawModes/PointMode";
import GraphMode from "@/helpers/drawModes/GraphMode";
import LevelStatusHelper from "@/helpers/LevelStatusHelper";

export default {
  name: "PtrBreadcrumb",
  components: { PtrIcon },
  props: {
    shouldHideBackButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isActive: false,
    buildingObject: undefined,
    levelObject: undefined
  }),

  computed: {
    ...mapState("MAP", ["isAddEditPanelActive"]),
    ...mapState("CONTENT", ["levelStatuses", "isFormDirty"]),
    breadcrumbList() {
      return this.$store.state.breadcrumbList;
    },
    backgroundLoadingRequestCount() {
      return this.$store.state.backgroundLoadingRequestCount;
    },
    isItInContentPagesAndIsThereAnyLevelStatusIsInProgress() {
      return this.$route.name !== "Levels" && LevelStatusHelper.isAnyLevelInProgress(this.levelStatuses);
    },
    isDisabled() {
      return (
        this.isAddEditPanelActive ||
        this.backgroundLoadingRequestCount > 0 ||
        this.$route.name === "UserAdd" ||
        this.$route.name === "UserEdit"
      );
    },
    breadcrumbText() {
      const routeConfig = this.getRouteConfig(this.$route.name);
      this.updateBreadCrumbList(routeConfig.listToUpdate, routeConfig.updateParams);
      this.$emit("textUpdated", routeConfig.emitText);
      return routeConfig.breadcrumb;
    },
    getSubRouteBreadcrumb() {
      return this.levelObject?.levelLongTitle || "";
    },
    ...mapState("CONTENT", ["clients", "sites", "buildings", "levels"]),
    ...mapGetters("MAP", ["currentClientObject", "currentSiteObject"])
  },

  watch: {
    buildings() {
      this.handleBuildingLevelObjectChange();
    },
    $route: {
      handler() {
        this.handleBuildingLevelObjectChange();
      },
      immediate: true
    },
    isActive() {
      this.$emit("isActiveChanged", this.isActive);
    }
  },

  methods: {
    getRouteConfig(routeName) {
      const configMap = {
        UserAdd: {
          listToUpdate: this.clients,
          updateParams: { routeName: "UserAdd", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        UserEdit: {
          listToUpdate: this.clients,
          updateParams: { routeName: "UserAdd", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        UsersAndPermissions: {
          listToUpdate: this.clients,
          updateParams: { routeName: "UsersAndPermissions", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        Metadata: {
          listToUpdate: this.clients,
          updateParams: { routeName: "Metadata", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        Configuration: {
          listToUpdate: this.clients,
          updateParams: { routeName: "Metadata", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        Site: {
          listToUpdate: this.clients,
          updateParams: { routeName: "Sites", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        GlobalGeofence: {
          listToUpdate: this.clients,
          updateParams: { routeName: "Sites", routeNameKey: "clientTitle" },
          emitText: "",
          breadcrumb: this.currentClientObject?.clientTitle
        },
        Building: {
          listToUpdate: this.sites,
          updateParams: {
            routeName: "Buildings",
            routeParamKey: "siteId",
            routeNameKey: "siteTitle",
            routeIdKey: "siteInternalIdentifier",
            parentTitle: this.currentClientObject?.clientTitle || ""
          },
          emitText: this.getTooltipText("breadcrumb.site", this.currentSiteObject?.siteTitle),
          breadcrumb: this.currentSiteObject?.siteTitle
        },
        OutdoorWayfindingNetwork: {
          listToUpdate: this.sites,
          updateParams: {
            routeName: "Buildings",
            routeParamKey: "siteId",
            routeNameKey: "siteTitle",
            routeIdKey: "siteInternalIdentifier",
            parentTitle: this.currentClientObject?.clientTitle || ""
          },
          emitText: this.getTooltipText("breadcrumb.site", this.currentSiteObject?.siteTitle),
          breadcrumb: this.currentSiteObject?.siteTitle
        },
        Level: {
          listToUpdate: this.buildings,
          updateParams: {
            routeName: "Levels",
            routeParamKey: "buildingId",
            routeNameKey: "buildingTitle",
            routeIdKey: "buildingInternalIdentifier",
            parentTitle: this.currentSiteObject?.siteTitle || ""
          },
          emitText: this.getTooltipText("breadcrumb.building", this.buildingObject?.buildingTitle),
          breadcrumb: this.buildingObject?.buildingTitle
        },
        Picker: this.getDefaultConfig("ContentPicker"),
        PoiList: this.getDefaultConfig("PoiList"),
        GeofenceList: this.getDefaultConfig("GeofenceList"),
        MapObjectList: this.getDefaultConfig("MapObjectList"),
        BeaconList: this.getDefaultConfig("BeaconList"),
        WayfindingNetwork: this.getDefaultConfig("WayfindingNetwork")
      };
      for (const key in configMap) {
        if (routeName.includes(key)) {
          return configMap[key];
        }
      }
      return this.getDefaultConfig("MapDesigner"); // Fallback configuration
    },
    getDefaultConfig(routeName) {
      return {
        listToUpdate: this.levels,
        updateParams: {
          routeName: routeName,
          routeParamKey: "levelId",
          routeNameKey: "levelLongTitle",
          routeIdKey: "levelIndex",
          parentTitle: this.buildingObject?.buildingTitle || ""
        },
        emitText: this.$t("breadcrumb.tooltip", { item: this.$t("breadcrumb.level") }),
        breadcrumb: this.getSubRouteBreadcrumb
      };
    },
    getTooltipText(itemKey, name) {
      return this.$t("breadcrumb.tooltip", {
        item: this.$t(itemKey),
        name: name ? `'${name}'` : ""
      });
    },
    isLevelStatusInProgress(levelIndex) {
      return LevelStatusHelper.isInProgressState(this.levelStatuses[levelIndex]?.levelStatus);
    },
    updateBreadCrumbList(listData, options) {
      let list = listData.map((element) => {
        return {
          title: element[options.routeNameKey],
          parentTitle: options.parentTitle,
          route: { name: options.routeName, params: { [options.routeParamKey]: element[options.routeIdKey] } },
          disable: options?.disableItems ? this.isLevelStatusInProgress(element?.levelIndex) : false
        };
      });
      this.$store.commit("BREADCRUMB_LIST", list);
    },
    handleRouteChange(index) {
      // CPD-1611. We're adding background loading and disabling interaction while changing map objects.
      this.$store.commit("ADD_BACKGROUND_LOADING_REQUEST");
      this.$emit("isActiveChanged", false);
      if (typeof this.breadcrumbList?.[index]?.route?.params?.levelId !== "undefined") {
        this.$store.commit("CONTENT/RESET_CONTENT_VISIBILITY");
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP");
        this.$store.commit("CONTENT/ORIGINAL_MAP_OBJECTS", []);
        PointMode.clearPoints();
        GraphMode.clear();
      }
      if (!this.isFormDirty) {
        this.$store.commit("CONTENT/INCR_REFRESH_KEY");
      }
    },
    handleBuildingLevelObjectChange() {
      const buildingId = this.$route.params.buildingId;
      const levelId = this.$route.params.levelId;
      if (buildingId !== undefined) {
        this.buildingObject = this.buildings?.find((building) => building.buildingInternalIdentifier == buildingId);
      }
      if (levelId !== undefined && this.buildingObject) {
        this.levelObject = this.buildingObject?.levels?.find((level) => level.levelIndex == levelId);
      }
    },
    onHomeButtonClicked() {
      this.$emit("isActiveChanged", false);
      this.$router.push({ name: "Clients" });
    }
  }
};
</script>

<style scoped lang="scss">
.breadcrumb-wrapper {
  z-index: 99 !important;
}
</style>
