<template>
  <div>
    <DropFile
      v-if="isFile"
      :id="title"
      ref="dropFileComponent"
      :value="files"
      :class="value ? 'mb-0' : 'mb-3'"
      :title="$t('no-content-added', { contentType: requestKey })"
      :subtitle="subtitle"
      :allowed-extensions="allowedExtensions"
      :max-file-size="property.maxFileSizeMB"
      :max-file-size-m-b="property.maxFileSizeMB"
      :restriction="restriction"
      :thumbnails="thumbnails"
      :label="title"
      is-bordered
      :aspect-ratio="expectedAspectRatio"
      :max-count="property.maxCount"
      :is-read-only="isReadOnly"
      @change="(file, index) => onAdd(file, index)"
      @remove="(data) => onFileRemove(data)"
      @clear="clearLogo"
    />
    <UrlAdder
      v-else
      ref="urlAdderComponent"
      v-model="urls"
      :max-count="property.maxCount"
      :restriction="restriction"
      :name="requestKey"
      :is-read-only="isReadOnly"
      @clear="clearLogo"
    ></UrlAdder>
  </div>
</template>

<script>
import DropFile from "@/components/shared/DropFile.vue";
import UrlAdder from "./UrlAdder.vue";
import ToastHelpers from "@/helpers/ToastHelpers";

export default {
  components: { DropFile, UrlAdder },
  props: {
    value: [String, Array, File],
    initialValue: File,
    property: Object,
    inputType: String,
    aspectRatio: String,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    files: undefined,
    allowedExtensions: ["jpg", "jpeg", "png"],
    thumbnails: undefined,
    urls: undefined,
    isFirstUpdate: true,
    changedFileIndex: undefined
  }),
  computed: {
    title() {
      return this.property.title;
    },
    restriction() {
      return this.property.restriction;
    },
    requestKey() {
      return this.property.requestKey;
    },
    subtitle() {
      if (this.requestKey === "logo") {
        return this.$t("no-content-added", { contentType: this.property.requestKey });
      } else {
        return this.$t("drag-or-browse");
      }
    },
    isFile() {
      return this.inputType === "dropfile";
    },
    expectedAspectRatio() {
      const index = this.aspectRatio.indexOf(":");
      const width = parseInt(this.aspectRatio.substring(0, index), 10);
      const height = parseInt(this.aspectRatio.substring(index + 1), 10);
      return width / height;
    },
    isFileValid() {
      return this.isFile && this.files !== undefined && this.files.length !== 0;
    }
  },
  watch: {
    value() {
      if (this.value === undefined) {
        this.files = [];
        return;
      }
      if (Array.isArray(this.value)) {
        if (this.isFile) {
          this.files = this.value;
        } else {
          this.urls = this.value;
        }
      } else if (typeof this.value === "string") {
        // Handle the case where `value` is a string
        // Convert it to an array
        this.urls = [this.value];
      } else {
        // Handle other cases here
        // provide a default value for `this.urls`
        this.urls = [];
      }
    },
    urls() {
      this.thumbnails = [...this.urls];
      if (this.isFirstUpdate) {
        this.isFirstUpdate = false;
        return;
      }
      if (this.requestKey === "logo") {
        this.$emit("input", this.urls?.[0]);
      } else {
        this.$emit("input", this.urls);
      }
    },
    isFileValid: {
      immediate: true,
      handler() {
        if (this.isFile) {
          this.$emit("isFileValid", this.isFileValid);
        }
      }
    }
  },
  created() {
    if (this.isFile) {
      this.files = this.value;
      return;
    }
    if (Array.isArray(this.value)) {
      this.urls = this.value;
    } else if (typeof this.value === "string") {
      // Handle the case where `value` is a string
      // Convert it to an array
      this.urls = [this.value];
    } else {
      // Handle other cases here
      // provide a default value for `this.urls`
      this.urls = [];
    }
  },
  methods: {
    onAdd(file, index) {
      this.changedFileIndex = index;
      const temp = this.files || [];
      if (temp[index]) {
        temp[index] = file;
      } else {
        temp.push(file);
      }
      this.files = [...temp];
      this.$emit("input", this.files);
      this.fileChanged(this.files, index);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      this.isFirstUpdate = false;
    },
    fileChanged(files, index) {
      if (!files || files.length === 0) {
        return;
      }
      let file;
      if (files[index]) {
        file = files[index];
      } else {
        file = files[files.length - 1];
      }

      if (!file) {
        return;
      }

      this.loadImage(file);
    },

    loadImage(file) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      const self = this;
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        const isAspectRatioInvalid = self.checkAspectRatio(width, height);
        if (isAspectRatioInvalid) {
          self.handleAspectRatioError();
          return;
        }

        self.handleValidImage(file, width, height);
      };
    },

    checkAspectRatio(width, height) {
      const ratio = width / height;
      const expectedAspectRatio = this.expectedAspectRatio;
      return ratio > expectedAspectRatio + 0.02 || ratio < expectedAspectRatio - 0.02;
    },

    handleAspectRatioError() {
      ToastHelpers.createErrorToast(
        `${this.$t(this.translationPath + "aspectRatio", {
          aspectRatio: this.aspectRatio
        })} `
      );
      this.$refs.dropFileComponent.removeFile();
      this.files = undefined;
    },

    handleValidImage(file, width, height) {
      const fileNameArr = file.name.split(".");
      const fileExtension = fileNameArr[fileNameArr.length - 1].toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension)) {
        this.handleInvalidFileExtension(fileExtension);
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = async () => {
        this.addThumbnail(reader.result, file, width, height);
      };
    },

    handleInvalidFileExtension(fileExtension) {
      const msg = this.allowedExtensions.join(", ");
      ToastHelpers.createErrorToast(
        `"${fileExtension}" ${this.$t(this.translationPath + "invalid-file-extension")} ` + msg
      );
    },

    addThumbnail(base64Result, file, width, height) {
      let temp = this.thumbnails || [];
      if (this.changedFileIndex !== undefined) {
        temp[this.changedFileIndex] = base64Result;
        this.changedFileIndex = undefined;
      } else {
        temp.push(base64Result);
      }

      const base64Part = base64Result.split(",")[1];
      file.base64 = base64Part;
      file.width = width;
      file.height = height;

      this.thumbnails = [...temp];
    },

    /**
     * Updates UrlList when the user clicks save
     * So that if the user enters a url as input but not pressed enter afterwards it is still added to urlList & saved
     */
    update() {
      this.$refs.urlAdderComponent?.update();
    },
    clearLogo() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      this.urls = [];
      this.files = [];
      this.thumbnails = undefined;
      this.isFirstUpdate = true;
    },
    onFileRemove(data) {
      const { indexToRemove, temp } = data;
      const tempThumbnails = this.thumbnails;
      tempThumbnails.splice(indexToRemove, 1);
      this.thumbnails = [...tempThumbnails];
      this.files = [...temp];
      this.$emit("input", this.files);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .restriction {
    font-weight: 400;
    color: var(--v-neutral-base);
  }
}
</style>
