<template>
  <div class="poi-property-input-container pa-2">
    <div class="property-header mb-2">{{ $t(translationPath + "rating") }}</div>
    <v-text-field
      ref="ratingInput"
      v-model.number="rating"
      class="mb-2"
      :rules="[rules.required, rules.rating]"
      :label="$t(translationPath + 'rating')"
      hide-details="auto"
      outlined
      dense
      type="number"
      hide-spin-buttons:
      :disabled="isReadOnly"
      @keydown="setFormDirty"
      @input="onRatingInput"
    />
    <v-text-field
      ref="maxRatingInput"
      v-model.number="ratingMax"
      class="mb-2"
      :rules="[rules.required, rules.ratingMax]"
      :label="$t(translationPath + 'ratingMax')"
      hide-details="auto"
      outlined
      dense
      type="number"
      hide-spin-buttons
      :disabled="isReadOnly"
      @keydown="setFormDirty"
      @input="onMaxRatingInput"
    />
    <v-text-field
      ref="numberOfRatingsInput"
      v-model.number="numberOfRatings"
      :label="$t(translationPath + 'numberOfRatings')"
      :rules="[rules.numberOfRatings]"
      hide-details="auto"
      outlined
      dense
      type="number"
      hide-spin-buttons
      :disabled="isReadOnly"
      @input="onNumberOfRatingsInput"
      @keypress="filterInput"
    />
  </div>
</template>

<script>
import ValidationHelpers from "@/helpers/ValidationHelpers";

export default {
  props: {
    value: Object,
    isReadOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      translationPath: "contents.mapDesigner.poiProperties.rating.",
      ratingMax: this.value?.ratingMax,
      rating: this.value?.rating,
      numberOfRatings: this.value?.numberOfRatings
    };
  },
  computed: {
    ratingObject() {
      return {
        rating: this.rating,
        ratingMax: this.ratingMax,
        numberOfRatings: this.numberOfRatings
      };
    },
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value),
        isInteger: (value) => Number.isInteger(value) || `${typeof value}`,
        rating: (value) => {
          if (!isNaN(this.ratingMax)) {
            return value <= this.ratingMax || this.$t(`${this.translationPath}lessThanMax`);
          }
          return true;
        },
        ratingMax: (value) => {
          if (!isNaN(this.ratingMax)) {
            return value >= this.rating || this.$t(`${this.translationPath}moreThanRating`);
          }
          return true;
        },
        numberOfRatings: (value) => {
          if (value === undefined || value === "") {
            return true;
          }
          if (!Number.isInteger(value)) {
            return this.$t(`${this.translationPath}mustBeInt`);
          }
          if (value >= 10 ** 7) {
            return this.$t(`${this.translationPath}mustBeUnder10Million`);
          }
          return true;
        }
      };
    }
  },
  watch: {
    ratingObject: {
      deep: true,
      handler() {
        this.$refs.ratingInput.validate();
        this.$refs.maxRatingInput.validate();
        this.$refs.numberOfRatingsInput.validate();
        this.$emit("input", this.ratingObject);
      }
    },
    value: {
      deep: true,
      handler() {
        this.ratingMax = this.value?.ratingMax;
        this.rating = this.value?.rating;
        this.numberOfRatings = this.value?.numberOfRatings;
      }
    }
  },
  methods: {
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    onRatingInput(val) {
      if (val < 0) {
        this.rating = 0;
      }
    },
    onMaxRatingInput(val) {
      if (val < 0) {
        this.maxRating = 0;
      }
    },
    onNumberOfRatingsInput(val) {
      if (val < 0) {
        this.numberOfRatings = 0;
      }
    },
    /**
     * Had to do this sort of thing on the integer value input
     * Because vuetify automatically converts the inputs into string
     * Whenever a "." or "," is entered (depending on locale settings
     * ^ still works weird on turkish keyboard (works like an escape and allows invalid input)
     */
    filterInput(inputEvent) {
      if (isNaN(inputEvent.key)) {
        inputEvent.preventDefault();
      } else {
        this.$refs.numberOfRatingsInput.validate();
        this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      }
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .vue-tel-input {
    border: 2px solid var(--v-scrollbarGray-base);
    box-shadow: none !important;
    border-radius: 8px;

    &:focus-within {
      background-color: var(--v-primary-lighten5) !important;
      border: 2px solid var(--v-primary-lighten3);
      border-radius: 8px;
    }
  }

  .vti__dropdown {
    border-radius: 4px;
    background-color: var(--v-scrollbarGray-base);
  }

  .vti__input {
    overflow: hidden;
    color: var(--v-neutral-darken3);
    text-overflow: ellipsis;
    font-size: #{$font-size-2};
    line-height: #{$line-height-10}; /* 200% */
  }

  .vti__dropdown-list {
    padding: 0;
    width: 248px !important;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-color: var(--v-white-base);
    margin-left: -10px;

    * {
      font-size: #{$font-size-0};
      color: var(--v-neutral-base);
      font-weight: 400;
    }

    li {
      padding-left: 0;
      padding-right: 0;
      max-width: 248px;

      white-space: no-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.invalid-input {
  ::v-deep {
    .vue-tel-input {
      border-color: var(--v-error-lighten2) !important;
    }

    .vti__input {
      color: var(--v-error-base);
    }
  }
}

.error-message {
  color: var(--v-error-base);
  font-size: #{$font-size-2};
  padding: 0 8px;
}
</style>
