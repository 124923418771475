<template>
  <div>
    <div class="transition-link-sub-title mt-6 paragraph-s">{{ $t(`${translationPath}configuration`) }}</div>
    <v-form ref="mdpoi" v-model="areTransitionPropertiesValid" @submit.prevent="() => {}">
      <v-row class="mt-4">
        <v-col class="py-0">
          <v-text-field
            :value="portalTravelTime"
            :label="$t(`${translationPath}travel-time`)"
            :rules="[rules.featureTravelTime]"
            outlined
            hide-details="auto"
            dense
            :disabled="isReadOnly"
            @input="(newVal) => onPortalTravelTimeChanged(newVal)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-4">
          <v-switch
            :input-value="isAccessible"
            :label="$t(`${translationPath}is-accessible`)"
            color="primary"
            hide-details
            inset
            :disabled="isReadOnly"
            @change="(newVal) => onIsAccessibleChanged(newVal)"
          ></v-switch>
          <v-switch
            :input-value="isComfortable"
            :label="$t(`${translationPath}is-comfortable`)"
            color="primary"
            hide-details
            inset
            :disabled="isReadOnly"
            @change="(newVal) => onIsComfortableChanged(newVal)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import ValidationHelpers from "@/helpers/ValidationHelpers";

export default {
  name: "TransitionConfiguration",
  props: {
    portalTravelTime: [String, Number],
    isAccessible: Boolean,
    isComfortable: Boolean,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    areTransitionPropertiesValid: false
  }),
  computed: {
    rules() {
      return {
        featureTravelTime: (value) => {
          return ValidationHelpers.isFloat(value) || this.$t(`${this.translationPath}invalid-travel-time`);
        },
        required: (value) => ValidationHelpers.isRequired(value)
      };
    }
  },
  watch: {
    areTransitionPropertiesValid() {
      this.$emit("transitionPropertiesValid", this.areTransitionPropertiesValid);
    }
  },
  methods: {
    onPortalTravelTimeChanged(newVal) {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      this.$emit("portalTravelTimeChanged", Number(newVal) || 0);
    },
    onIsAccessibleChanged(newVal) {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      this.$emit("isAccessibleChanged", newVal);
    },
    onIsComfortableChanged(newVal) {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      this.$emit("isComfortableChanged", newVal);
    }
  }
};
</script>
<style lang="scss" scoped>
.transition-link-sub-title {
  font-weight: #{$font-weight-semi-bold};
}
</style>
