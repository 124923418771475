<template>
  <div>
    <div class="transition-node-list-container">
      <div
        v-for="(edge, index) in displayedEdgeItems"
        :key="JSON.stringify(edge)"
        class="link-container d-flex justify-start"
      >
        <div class="vertical-line d-flex flex-column align-center justify-flex-start mt-6">
          <div class="circle big"></div>
          <div v-for="innerIndex in 5" :key="`${edge.a}${innerIndex}`" class="circle small"></div>
          <div v-if="edge.b" class="circle big"></div>
          <div
            class="direction-button-container clickable"
            :class="{ rotate: directionOptions[edge.direction].rotate }"
            @click="() => (isReadOnly ? null : directionUpdated(index))"
          >
            <PtrIcon :icon="directionOptions[edge.direction].icon" />
          </div>
        </div>
        <v-list class="py-0 transition-node-list">
          <v-list-item
            :class="{ 'current-list-item': edge.a.fid === currentFid && index === firstIndexOfCurrentNode }"
            class="px-0"
            two-line
            transition="scroll-y-transition"
          >
            <v-list-item-content>
              <v-list-item-title class="paragraph-s">{{ edge.a.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="!isOutdoor">{{ edge.a.secondaryText }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon v-if="!isReadOnly" class="delete-icon" @click="edgeRemoved(edge.a, index)">
              <PtrIcon icon="cancel-circle" class="mr-3" />
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="edge.b"
            :class="{
              'current-list-item': edge.b.fid === currentFid && index === firstIndexOfCurrentNode
            }"
            class="px-0"
            two-line
            transition="scroll-y-transition"
          >
            <v-list-item-content>
              <v-list-item-title class="paragraph-s">{{ edge.b.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="!isOutdoor">{{ edge.b.secondaryText }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="delete-icon" @click="edgeRemoved(edge.b, index)">
              <PtrIcon icon="cancel-circle" class="mr-3" />
            </v-list-item-icon>
          </v-list-item>
          <v-list-item v-else class="px-0 select-feature-button-list-item" two-line transition="scroll-y-transition">
            <v-list-item-content class="select-feature-button-container mt1">
              <v-menu bottom transition="slide-y-transition" offset-y content-class="transition-link-options-dropdown">
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="link-feature-button mt-2"
                    v-bind="attrs"
                    text
                    :disabled="isReadOnly"
                    v-on="on"
                    @click="addNewClicked(index)"
                  >
                    <div class="circle add-icon d-flex align-center justify-center">
                      <v-icon style="color: white" small>mdi-plus</v-icon>
                    </div>
                    <div class="link-feature-button-description d-flex flex-column align-start justify-start ml-2">
                      <span class="button-text paragraph-s">{{ $t("addNew") }}</span>
                      <div class="description paragraph-xs">
                        {{ $t(`${translationPath}add-transition-link-description`) }}
                      </div>
                    </div>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader>
                    {{
                      $t(
                        `${translationPath}${
                          dropdownOptions?.length !== 0 ? "quick-access" : "no-available-transitions"
                        }`
                      )
                    }}
                  </v-subheader>
                  <v-list-item
                    v-for="(dropdownFeature, dropdownIndex) in dropdownOptions"
                    :key="dropdownIndex"
                    class="align-start justify-start"
                    @click="linkFeature(dropdownFeature, index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="paragraph-s">
                        {{ dropdownFeature?.properties.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="!isOutdoor" class="paragraph-xs">
                        {{ `${getBuildingName(dropdownFeature)}/${getLevelShortTitle(dropdownFeature)}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="d-flex" style="flex-direction: row">
                      <v-btn class="v-btn--square" small icon depressed plain @click.stop="scrollTo(dropdownFeature)">
                        <PtrIcon icon="selected" />
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <v-menu
      v-if="!edgeBeingCreated && dropdownOptions?.length !== 0"
      bottom
      transition="slide-y-transition"
      offset-y
      content-class="transition-link-options-dropdown"
    >
      <template #activator="{ on, attrs }">
        <v-btn class="create-new-link-button mt-2" v-bind="attrs" text v-on="on" @click="$emit('createNewLinkClicked')">
          <div class="circle add-icon d-flex align-center justify-center">
            <v-icon color="white" small>mdi-plus</v-icon>
          </div>
          <div class="d-flex flex-column align-start justify-start ml-2">
            <span class="button-text paragraph-s">{{ $t(`${translationPath}create-new-link`) }}</span>
            <div class="description paragraph-xs">{{ $t(`${translationPath}add-transition-link-description`) }}</div>
          </div>
        </v-btn>
      </template>

      <v-list dense>
        <v-subheader>{{ $t(`${translationPath}quick-access`) }}</v-subheader>
        <v-list-item
          v-for="(dropdownFeature, index) in dropdownOptions"
          :key="index"
          class="align-start justify-start"
          @click="createNewLink(dropdownFeature)"
        >
          <v-list-item-content>
            <v-list-item-title class="paragraph-s">
              {{ dropdownFeature?.properties.name || $t(`${translationPath}custom-transition`) }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="!isOutdoor" class="paragraph-xs">
              {{ `${getBuildingName(dropdownFeature)}/${getLevelShortTitle(dropdownFeature)}` }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex" style="flex-direction: row">
            <v-btn class="v-btn--square" small icon depressed plain @click.stop="scrollTo(dropdownFeature)">
              <PtrIcon icon="selected" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else-if="dropdownOptions?.length === 0" class="paragraph-s mb-n2 mt-2">
      {{ $t(`${translationPath}no-available-transitions`) }}
    </div>
    <TransitionConfiguration
      :portal-travel-time="portalTravelTime"
      :is-accessible="isAccessible"
      :is-comfortable="isComfortable"
      :is-read-only="isReadOnly"
      @portalTravelTimeChanged="(newVal) => onPortalTravelTimeChanged(newVal)"
      @isAccessibleChanged="(newVal) => onIsAccessibleChanged(newVal)"
      @isComfortableChanged="(newVal) => onIsComfortableChanged(newVal)"
      @transitionPropertiesValid="
        (areTransitionPropertiesValid) => $emit('transitionPropertiesValid', areTransitionPropertiesValid)
      "
    ></TransitionConfiguration>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import TransitionConfiguration from "@/components/mapDesigner/TransitionConfiguration.vue";
import TransitionTaxonomy from "@/constants/transitionTaxonomy";
import MapHelpers from "@/helpers/MapHelpers";

export default {
  name: "CustomTransitionLinks",
  components: { PtrIcon, TransitionConfiguration },
  props: {
    currentNodeObject: Object,
    currentPortalGroupId: String,
    edgeList: Array, // {a:from-fid, b: to-id, direction: a-to-b/b-to-a/bi}
    nodesIdsToAdd: Array,
    portalTravelTime: [String, Number],
    isAccessible: Boolean,
    isComfortable: Boolean,
    isOutdoor: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    directionOptions: {
      bi: { icon: "both-ways", rotate: false },
      "a-to-b": { icon: "one-way", rotate: false },
      "b-to-a": { icon: "one-way", rotate: true }
    },
    indexToLinkCustomFeature: -1
  }),
  computed: {
    ...mapState("CONTENT", ["buildings", "levels", "graphs"]),
    ...mapGetters("MAP", ["currentBuildingObject", "currentLevelObject"]),
    taxonomy() {
      return TransitionTaxonomy.TRANSITION_TYPES;
    },
    customTransitionNodes() {
      if (this.isOutdoor) {
        return this.graphs.filter(
          (feature) =>
            feature?.properties?.bid === undefined &&
            feature?.properties?.lvl === undefined &&
            feature?.properties?.typeCode === "custom-transition" &&
            TransitionTaxonomy.NON_CUSTOM_TYPES.indexOf(feature?.properties?.typeCode) === -1
        );
      }
      return this.graphs.filter(
        (feature) =>
          feature?.properties?.typeCode !== "graph-node" &&
          feature?.properties?.bid !== undefined &&
          feature?.properties?.lvl !== undefined &&
          TransitionTaxonomy.NON_CUSTOM_TYPES.indexOf(feature?.properties?.typeCode) === -1
      );
    },
    idsToNodes() {
      const nodesDict = this.customTransitionNodes.reduce((idToNodesObject, transitionNode) => {
        idToNodesObject[transitionNode?.properties.fid] = transitionNode;
        return idToNodesObject;
      }, {});
      nodesDict[this.currentNodeObject?.properties.fid] = this.currentNodeObject;
      return nodesDict;
    },
    idsToEdgeCount() {
      const idsToEdgeCount = {};
      this.edgeList.forEach((edge) => {
        const aFid = edge.a;
        const bFid = edge.b;
        if (idsToEdgeCount[aFid]) {
          idsToEdgeCount[aFid] += 1;
        } else {
          idsToEdgeCount[aFid] = 1;
        }
        if (bFid) {
          if (idsToEdgeCount[bFid]) {
            idsToEdgeCount[bFid] += 1;
          } else {
            idsToEdgeCount[bFid] = 1;
          }
        }
      });
      return idsToEdgeCount;
    },
    maxEdgeCountForAFeature() {
      return this.customTransitionNodes?.length - 1;
    },
    displayedEdgeItems() {
      const processedEdgeList = [];
      this.edgeList.forEach((edge) => {
        const aObject = this.getDisplayedItem(edge.a);
        let bObject;
        if (edge.b) {
          bObject = this.getDisplayedItem(edge.b);
          processedEdgeList.push({ a: aObject, b: bObject, direction: edge.direction });
        } else if (this.dropdownOptions.length !== 0) {
          // Do not display an uncompleted edge if the dropdown is empty
          processedEdgeList.push({ a: aObject, direction: edge.direction });
        }
      });
      console.log(processedEdgeList);
      return processedEdgeList;
    },
    edgeBeingCreated() {
      return this.edgeList.find((edge) => edge.b === undefined);
    },
    dropdownOptions() {
      if (!this.edgeBeingCreated) {
        const currentNodeExists = this.customTransitionNodes.some(
          (node) => node.properties.fid === this.currentNodeObject.properties.fid
        );
        let temp = [...this.customTransitionNodes];
        if (!currentNodeExists) {
          temp.push(this.currentNodeObject);
        }
        return temp.filter((feature) => this.idsToEdgeCount[feature.properties.fid] < this.maxEdgeCountForAFeature);
      }
      const idOfFeatureToLink = this.edgeBeingCreated.a;
      const edgesThatFeatureInvolved = this.edgeList.filter(
        (edge) => edge.a === idOfFeatureToLink || edge.b === idOfFeatureToLink
      );
      let featuresThatAreAlreadyLinkedToTheFeature = edgesThatFeatureInvolved.map((edge) => {
        if (edge.a === idOfFeatureToLink) {
          return edge.b;
        }
        return edge.a;
      });
      return this.customTransitionNodes
        .filter(
          (feature) =>
            featuresThatAreAlreadyLinkedToTheFeature.findIndex(
              (fid) => fid === feature.properties.fid || idOfFeatureToLink === feature.properties.fid
            ) === -1
        )
        .sort((a, b) => {
          return a?.properties?.lvl - b?.properties?.lvl;
        });
    },
    currentFid() {
      return this.currentNodeObject.properties.fid;
    },
    firstIndexOfCurrentNode() {
      return this.edgeList.findIndex((edge) => edge.a === this.currentFid || edge.b === this.currentFid);
    }
  },
  watch: {
    nodesIdsToAdd() {
      const fid = this.nodesIdsToAdd[0];
      const feature = this.idsToNodes[fid];
      if (feature) {
        this.add(feature);
      }
    }
  },
  methods: {
    getName(node) {
      if (node === undefined) {
        return;
      }
      const name = node?.properties.name || "Custom Transition";
      return name;
    },
    getDisplayedItem(fid) {
      const feature = this.idsToNodes[fid];
      let name = this.getName(feature);
      if (name?.length > 20) {
        name = name.substring(0, 20).padEnd(23, ".");
      }
      if (fid === this.currentNodeObject?.properties.fid) {
        name = name.concat(" (Current Node)");
      }
      const building = this.buildings?.find(
        (building) => building.buildingInternalIdentifier === feature?.properties?.bid
      );
      const buildingName = building?.buildingTitle;
      const levelShortTitle = building?.levels.find(
        (level) => level.levelIndex === feature?.properties.lvl
      )?.levelShortTitle;
      return { fid, name, secondaryText: `${buildingName} / ${levelShortTitle}` };
    },
    linkFeature(feature, indexToLink) {
      const fid = feature.properties.fid;
      const newlyLinkedFeaturesGroupId = feature?.properties.portalGroupId;
      const idx = this.nodesIdsToAdd.findIndex((id) => id === fid);
      if (!newlyLinkedFeaturesGroupId || newlyLinkedFeaturesGroupId === this.currentPortalGroupId || idx !== -1) {
        const index = indexToLink || this.indexToLinkCustomFeature;
        this.$emit("linkFeature", { feature, index });
      } else {
        // indexToLink is undefined when transition selected by map click.
        // in this case, index is where the b (the second node on the edge) is undefined
        this.$emit("mergeWithCurrentGroup", {
          feature,
          index: indexToLink ?? this.displayedEdgeItems.findIndex((edge) => edge.b === undefined)
        });
      }
      this.indexToLinkCustomFeature = -1;
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    },
    createNewLink(feature) {
      this.$emit("createLink", { feature });
    },
    edgeRemoved(listItem, index) {
      this.$emit("edgeRemoved", { fid: listItem.fid, index });
      this.indexToLinkCustomFeature = -1;
      this.$store.commit("MAP/CLICKED_TRANSITION_FEATURE", undefined);
    },
    directionUpdated(index) {
      const currentDirection = this.edgeList[index].direction;
      const currIdx = Object.keys(this.directionOptions).indexOf(currentDirection);
      const directionCount = Object.keys(this.directionOptions)?.length;
      const newIdx = (currIdx + 1) % directionCount;
      const newDirection = Object.keys(this.directionOptions)[newIdx];
      this.$emit("directionUpdated", { index, newDirection });
    },
    scrollTo(item) {
      const coordinates = { lng: item.geometry.coordinates[0], lat: item.geometry.coordinates[1] };
      MapHelpers.jumpToPoint(coordinates);
    },
    addNewClicked(index) {
      this.indexToLinkCustomFeature = index;
      this.$emit("addNewClicked");
      if (this.dropdownOptions?.length !== 0) {
        if (!this.isOutdoor) {
          this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "buildingLevelSelector" });
        }
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.choose-transition"));
      }
    },
    getBuildingName(feature) {
      const buildingObject = this.buildings.find(
        (building) => building.buildingInternalIdentifier === feature?.properties.bid
      );
      return buildingObject?.buildingTitle;
    },
    getLevelShortTitle(feature) {
      const buildingObject = this.buildings.find(
        (building) => building.buildingInternalIdentifier === feature?.properties.bid
      );
      const levelObject = buildingObject?.levels.find((level) => level.levelIndex === feature?.properties.lvl);
      return levelObject?.levelShortTitle;
    },
    onPortalTravelTimeChanged(newVal) {
      this.$emit("portalTravelTimeChanged", Number(newVal) || 0);
    },
    onIsAccessibleChanged(newVal) {
      this.$emit("isAccessibleChanged", newVal);
    },
    onIsComfortableChanged(newVal) {
      this.$emit("isComfortableChanged", newVal);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.link-container {
  gap: #{$spacing-m};
}

.select-feature-button-list-item {
  margin-left: -8px;

  ::v-deep .v-btn__content {
    justify-content: flex-start !important;
  }
}

.select-feature-button-container {
  margin-left: -15px;
  overflow: visible;
}

.transition-node-list-container {
  padding-left: 7px;

  .v-list-item__content {
    padding: 4px 0;

    &:not(.select-feature-button-container) {
      max-width: calc(100% - 24px);
      width: 148px;
    }
  }

  .v-list-item--two-line {
    min-height: 52px;
    height: 52px;
  }
}

.create-new-link-button,
.link-feature-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: transparent;

  .button-text {
    color: var(--v-primary-base);
  }

  .description {
    color: var(--v-primary-lighten1);
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.create-new-link-button {
  .description {
    max-width: 180px;
  }

  .v-btn__content {
    gap: 6px;
  }
}

.link-feature-button {
  .link-feature-button-description {
    width: 185px;
  }
}

.current-list-item {
  .delete-icon {
    display: none;
  }
}

.delete-icon {
  cursor: pointer;
}

.vertical-line {
  gap: #{$spacing-s};
  height: 52px;
  position: relative;
}

.circle {
  border-radius: 100%;
  flex-shrink: 0;

  &.big {
    background-color: var(--v-primary-base);
    width: 8px;
    height: 8px;
  }

  &.small {
    background-color: var(--v-primary-lighten1);
    width: 4px;
    height: 4px;
  }

  &.add-icon {
    background-color: var(--v-primary-base);
    width: 20px;
    height: 20px;
  }
}

.v-list-item__title {
  margin-bottom: 0;
}

.v-list-item__subtitle {
  font-size: #{$font-size-1} !important;
}

button {
  &.pl-0 {
    padding-left: 0 !important;
  }
}

::v-deep .v-menu__content {
  width: 208px;
}

::v-deep .v-list-item__title {
  align-self: start;
}

.direction-button-container {
  position: absolute;
  top: calc(50% - 6px);

  &.clickable {
    cursor: pointer;
  }
}

.transition-node-list {
  width: calc(100% - 8px);
}

.rotate {
  .custom-icon {
    ::v-deep {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.hidden {
  display: none;
}

.v-list-item:hover {
  border-color: transparent !important;
}
</style>
