import vm from "../main";
import axios from "./PointrCloudAxios";

// const apiUrl = "https://192.168.0.29:3000";

export default class UserService {
  // GET api/v8/clients/{clientInternalIdentifier}/users
  static async getUsers(payload) {
    const { clientInternalIdentifier } = payload;
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/users`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.results;
  }
  static async getUser(payload) {
    //"/api/v8/clients/4aa45318-55e4-4588-840d-ae596fc4920b/users/10
    const { clientInternalIdentifier, userId } = payload;
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/users/${userId}`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async addUser(payload) {
    const { clientInternalIdentifier, email } = payload;
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/clients/${clientInternalIdentifier}/users`, { email });
    } catch (error) {
      console.error(error);
      return error.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async addPermissions(payload) {
    const { userId, permissions } = payload;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      await axios.put(`/users/${userId}/permissions`, permissions);
      return { status: "success" };
    } catch (error) {
      console.error(error);
      return { status: "error" };
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
  }

  static async deleteUser(payload) {
    const { clientInternalIdentifier, userId } = payload;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      await axios.delete(`/clients/${clientInternalIdentifier}/users/${userId}`);
      return { status: "success", userId };
    } catch (error) {
      console.error(error);
      return error.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
  }

  static async editUser(payload) {
    const { clientInternalIdentifier, userId, email } = payload;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      await axios.patch(`/clients/${clientInternalIdentifier}/users/${userId}`, { email });
      return { status: "success" };
    } catch (error) {
      console.error(error);
      return { status: "error" };
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
  }
}
