import Helpers from "@/helpers/Helpers";
import ConfigurationRoutes from "./configRoutes";
import ContentRoutes from "./contentRoutes";

import ContentView from "@/views/ContentView.vue";

export default {
  path: "/cms",
  component: Helpers.lazyLoad("CmsView"),
  children: [
    {
      path: "activity",
      name: "Activity",
      component: Helpers.lazyLoad("PtrSidebar"),
      meta: { requiresAuth: true }
    },
    {
      path: "content",
      name: "Content",
      redirect: "/cms/content/clients",
      components: {
        default: ContentView,
        PublishModal: Helpers.lazyLoad("PublishModal", "components/preview"),
        InformationModals: Helpers.lazyLoad("InformationModals", "components/shared")
      },
      children: ContentRoutes,
      meta: { requiresAuth: true }
    },
    // {
    //   path: "config",
    //   name: "Config",
    //   redirect: "/cms/config/users-and-permissions",
    //   component: Helpers.lazyLoad("ConfigurationView"),
    //   children: ConfigurationRoutes,
    //   meta: { requiresAuth: true }
    // },
    {
      path: "reports",
      name: "Reports",
      component: Helpers.lazyLoad("PtrSidebar"),
      meta: { requiresAuth: true }
    },
    {
      path: "analytics/:listItem?",
      name: "Analytics",
      component: Helpers.lazyLoad("AnalyticsView"),
      meta: { requiresAuth: true }
    },
    {
      path: "embedError",
      name: "embedError",
      component: Helpers.lazyLoad("EmbedErrorView"),
      meta: { access: "embed" }
    },
    {
      path: "settings",
      name: "Config",
      redirect: "/cms/settings/users-and-permissions",
      component: Helpers.lazyLoad("SettingsView"),
      children: ConfigurationRoutes,
      meta: { requiresAuth: true }
    },
    {
      path: "config/content-settings",
      name: "ContentSettings",
      component: Helpers.lazyLoad("ContentSettingsView"),
      meta: { requiresAuth: true }
    }
  ]
};
