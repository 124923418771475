<template>
  <!-- This is removed due to new config and publish buttons are not for this release -->
  <!-- <div class="lds-ring" :class="`color-${color}`" :style="spinnerStyle">
    <div v-for="i in 4" :key="i" :style="ringStyle"></div>
    <div v-for="i in 4" :key="i" :style="ringStyle"></div>
  </div> -->

  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
};
// This is removed due to new config and publish buttons are not for this release
// export default {
//   name: "LoadingSpinner",
//   props: {
//     color: { type: String, default: "" },
//     size: { type: Number, default: 32 } // Default size is 32px
//   },
//   computed: {
//     spinnerStyle() {
//       return {
//         width: `${this.size}px`,
//         height: `${this.size}px`
//       };
//     },
//     ringStyle() {
//       const borderThickness = this.size / 8; // Adjust border thickness based on size
//       const boxShadowValue = `0 0 ${borderThickness}px ${borderThickness / 2}px var(--v-white-base)`;
//       return {
//         width: `${this.size}px`,
//         height: `${this.size}px`,
//         borderWidth: `${borderThickness}px`,
//         boxShadow: boxShadowValue
//       };
//     }
//   }
// };
</script>

<style lang="scss" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 44px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -22px;
  width: 32px;
  height: 32px;
  margin: 0px 4px 4px 8px;
  border: 2px solid var(--v-white-base);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.25, 0, 0.5, 1) infinite;
  border-color: var(--v-white-base) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// This is removed due to new config and publish buttons are not for this release
// .lds-ring {
//   display: inline-block;
//   position: relative;
// }
// .lds-ring div {
//   box-sizing: border-box;
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   border-style: solid;
//   border-radius: 50%;
//   animation: lds-ring 1.2s cubic-bezier(0.25, 0, 0.5, 1) infinite;
//   border-color: var(--v-white-base) transparent transparent transparent;
// }
// .lds-ring.color-primary div {
//   //TODO: define theme colors (500 and 200 here)
//   border-color: #356df1 rgba(164, 190, 249, 1) rgba(164, 190, 249, 1) rgba(164, 190, 249, 1);
// }
// .lds-ring div:nth-child(1) {
//   animation-delay: -0.45s;
// }
// .lds-ring div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .lds-ring div:nth-child(3) {
//   animation-delay: -0.15s;
// }
// @keyframes lds-ring {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
</style>
