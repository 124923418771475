<template>
  <div>
    <div :class="{ 'bordered-container': isBordered }">
      <div v-if="label" class="label ml-n2 pl-2">{{ label }}</div>
      <div v-if="isMultiple && maxCount" class="count-info" :class="!isFileAdded ? 'mb-5' : ''">
        {{ files?.length || 0 }}/{{ maxCount }} images
      </div>
      <div v-if="isFileAdded">
        <div
          v-for="(file, index) in files"
          :key="index"
          class="drop-file disable-hover-effect my-0 file"
          :class="{
            'no-border': files !== undefined,
            'multiple-item': isMultiple && isFileAdded,
            'no-margin': maxCount === files?.length
          }"
        >
          <v-row class="content px-0 my-0" :class="isImageFile ? 'py-1' : 'py-3'">
            <div
              class="d-flex align-center"
              :class="
                isImageFile
                  ? 'image-file-background justify-start'
                  : 'button-background paragraph-s justify-space-between py-3 px-2'
              "
            >
              <img v-if="thumbnails" :src="thumbnails[index]" class="thumbnail" alt="thumbnail" />
              <div class="file-name-container">{{ getFilename(index) }}</div>
              <div class="right-button-container ml-auto d-flex">
                <v-btn v-if="isImageFile" class="ml-auto" small icon depressed plain @click="openImageCropper(index)">
                  <PtrIcon icon="edit" width="14.4px" class="button-icon" color="neutral" />
                </v-btn>
                <v-btn v-if="isImageFile" class="ml-auto" small icon depressed plain @click="removeFile(index)">
                  <PtrIcon icon="close" width="14.4px" class="button-icon" color="neutral" />
                </v-btn>
                <v-btn v-else-if="isEdit()" small icon depressed plain>
                  <PtrIcon icon="download" class="button-icon" />
                </v-btn>
                <v-btn v-else small icon depressed plain @click.stop="removeFile(index)">
                  <PtrIcon icon="clear" class="button-icon" />
                </v-btn>
              </div>
            </div>
          </v-row>
        </div>
      </div>

      <div
        v-if="shouldShowInput"
        class="mb-2 drop-file"
        :class="{
          'hover-file': dragging,
          bordered: isBordered,
          'lower-border-radius': isMultiple && isFileAdded
        }"
        @dragover="dragging = true"
        @dragleave="dragging = false"
      >
        <v-row class="content my-0 py-0 px-4" @drag="onChange">
          <v-col v-if="!isMultiple || (isMultiple && !isFileAdded)" class="d-flex flex-column align-center py-2 px-4">
            <PtrIcon icon="cloud-up" width="28" height="28" class="ma-2" />
            <div class="drop-file-title">{{ title }}</div>
            <div v-if="subtitle" class="drop-file-subtitle property-header">{{ subtitle }}</div>
            <slot name="description"></slot>
          </v-col>
          <v-col v-else class="d-flex align-center justify-start py-1 multiple-input">
            <PtrIcon icon="plus" width="12" height="12" />
            <div>{{ $t(`${translationPath}addAnotherImage`) }}</div>
          </v-col>
        </v-row>
        <input ref="fileInput" :key="fileInputKey" type="file" @change="onChange" />
      </div>

      <div v-if="isLoading" class="drop-file">
        <v-row class="content pa-3" @drag="onChange">
          <v-col cols="10">
            <div class="drop-file-title">{{ progress }}%</div>
            <div class="drop-file-subtitle">{{ $t(`${translationPath}processing`) }}</div>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-progress-circular :width="3" indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="!files && !isLoading && restriction" class="restriction paragraph-xs my-0">{{ restriction }}</v-row>
    </div>
    <ImageCropperPopup
      v-if="imageToCrop !== undefined"
      :file="imageToCrop"
      :aspect-ratio="aspectRatio"
      :is-edit="isImageCropperEdit"
      @close="revertCrop"
      @imageCropped="(url) => onImageCropped(url)"
      @cancel="removeImageFromUneditedFiles"
    />
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import ToastHelpers from "@/helpers/ToastHelpers";
import ImageCropperPopup from "./ImageCropperPopup.vue";

export default {
  name: "DropFile",
  components: { PtrIcon, ImageCropperPopup },
  props: {
    value: [File, Array],
    title: { type: String, default: "title" },
    subtitle: String,
    allowedExtensions: Array,
    // {"dxf": 100, "dwg":50}
    fileSizeWithExtension: Object,
    maxFileSizeMB: { type: Number, default: 100 },
    isLoading: { type: Boolean, default: false },
    restriction: String,
    dropAnywhere: { type: Boolean, default: false },
    thumbnails: Array,
    label: String,
    isBordered: { type: Boolean, default: false },
    maxCount: { type: Number, default: 1 },
    aspectRatio: Number,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.feature.",
    fileInputKey: 0,
    files: [],
    uneditedFiles: [],
    dragging: false,
    progress: 50,
    isDragOver: false,
    interval: null,
    showEditPopup: false,
    editFileIndex: null,
    imageToCrop: undefined,
    indexToCrop: undefined,
    isImageCropped: false,
    isImageCropperEdit: false
  }),
  computed: {
    shouldShowInput() {
      return this.isInitialState() || (this.isFileAdded && this.isMultiple && this.files?.length < this.maxCount);
    },
    isFileAdded() {
      return this.files && this.files.length > 0 && !this.isLoading;
    },
    isMultiple() {
      return this.maxCount > 1;
    },
    isImageFile() {
      return this.files && this.files[0]?.["type"]?.split("/")[0] === "image";
    }
  },
  watch: {
    value() {
      if (this.value === undefined) {
        this.files = [];
      }
    }
  },
  created() {
    this.files = this.value;
    if (this.dropAnywhere) {
      document.addEventListener("dragover", this.onDragOver, true);
    }
  },
  beforeDestroy() {
    if (this.dropAnywhere) {
      document.removeEventListener("dragover", this.onDragOver, true);
    }
  },
  methods: {
    onChange(e) {
      if (this.files?.length === this.maxCount) {
        return;
      }
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      Object.values(files).forEach((file) => {
        if (this.uneditedFiles.some((existingFile) => existingFile.name === file.name)) {
          ToastHelpers.createErrorToast(`File "${file.name}" already exist.`);
          this.dragging = false;
          return;
        }
        this.createFile(file);
      });
      if (this.files === undefined) {
        this.$refs.fileInput.value = null;
      }
    },
    createFile(file) {
      const nameParts = file?.name?.split(".");
      const fileExtension = nameParts[nameParts.length - 1]?.toLowerCase();
      const isExtensionSupported = this.allowedExtensions?.length && this.allowedExtensions.includes(fileExtension);

      if (!isExtensionSupported) {
        let msg =
          "File should be one of types: " +
          this.allowedExtensions.reduce((exts, ext) => {
            exts += exts.length == 0 ? ext : `, ${ext}`;
            return exts;
          }, "");
        ToastHelpers.createErrorToast(msg);
        this.dragging = false;
        return;
      }

      if (file.size > this.maxFileSizeMB * 1000 * 1000) {
        ToastHelpers.createErrorToast(`Please check file size. It cannot exceed ${this.maxFileSizeMB} MB.`);
        this.dragging = false;
        return;
      }

      if (
        this.fileSizeWithExtension?.[fileExtension] &&
        file.size > this.fileSizeWithExtension[fileExtension] * 1000 * 1000
      ) {
        ToastHelpers.createErrorToast(
          `Please check file size. ${fileExtension} files cannot exceed ${this.fileSizeWithExtension[fileExtension]} MB.`
        );
        this.dragging = false;
        return;
      }
      this.dragging = false;
      const originalImage = file;
      this.uneditedFiles.push(originalImage);

      if (this.aspectRatio) {
        this.isImageCropperEdit = false;
        this.imageToCrop = file;
      } else {
        this.emitFileEvent(file);
      }
    },
    revertCrop() {
      this.fileInputKey++;
      this.imageToCrop = undefined;
      this.indexToCrop = undefined;
    },
    removeImageFromUneditedFiles() {
      this.fileInputKey++;
      const indexToRemove = this.uneditedFiles.findIndex((file) => file.name === this.imageToCrop.name);
      if (indexToRemove !== -1) {
        this.uneditedFiles.splice(indexToRemove, 1);
      }
      this.imageToCrop = undefined;
    },
    removeFile(index) {
      this.fileInputKey++;
      const temp = this.files;
      const indexToRemove = index === undefined ? this.files.length - 1 : index;
      temp.splice(indexToRemove, 1);
      if (temp.length) {
        this.$emit("input", temp);
        this.$emit("remove", { indexToRemove, temp });
      } else {
        this.$emit("clear");
      }
      if (indexToRemove === this.files?.length && this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
      this.files = [...temp];
      this.uneditedFiles.splice(indexToRemove, 1);
    },
    onDragOver(event) {
      if (this.files?.length === this.maxCount) {
        return;
      }
      event.preventDefault();

      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.isOver = false;
        clearInterval(this.interval);
        this.interval = null;
        /*** callback for onDragLeave ***/
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$store.commit("CONTENT/IS_DRAGGING", false);
      }, 100);

      if (!this.isOver) {
        this.isOver = true;
        /*** callback for onDragEnter ***/
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
        this.$store.commit("CONTENT/IS_DRAGGING", true);
      }
    },
    getFilename(index) {
      return this.files?.[index]?.name?.split(".")?.[0] || "";
    },
    getExtension(index) {
      return this.files?.[index]?.name?.split(".")?.pop() || "";
    },
    isEdit() {
      return this.$route.name.includes("Edit");
    },
    isInitialState() {
      return (!this.files || this.files.length === 0) && !this.isLoading;
    },
    onImageCropped(blob) {
      const file = new File([blob], this.imageToCrop.name, { type: blob.type });
      this.imageToCrop = undefined;
      this.emitFileEvent(file, this.indexToCrop);
      this.indexToCrop = undefined;
    },
    emitFileEvent(file, index) {
      const temp = this.files || [];
      if (temp[index]) {
        temp[index] = file;
      } else {
        temp.push(file);
      }
      this.$emit("change", file, index);
      this.$emit("input", temp);
      this.files = [...temp];
    },
    openImageCropper(index) {
      this.isImageCropperEdit = true;
      this.imageToCrop = this.uneditedFiles[index];
      this.indexToCrop = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-file {
  position: relative;
  border: 2px solid var(--v-scrollbarGray-base) !important;
  border-radius: 8px;

  &.no-border {
    border: 2px solid transparent !important;
    width: calc(100% + 4px);
    margin-left: -2px;
  }

  &.bordered {
    margin-top: -16px;
  }

  &.lower-border-radius {
    border-radius: 4px;
    margin-bottom: 0 !important;
  }

  &.multiple-item {
    &:last-child {
      &:not(.no-margin) {
        margin-bottom: 14px !important;
      }
    }

    &:not(:first-child) {
      &:not(:only-child) {
        margin-top: -8px !important;
      }
    }
  }

  &.file {
    &:not(.multiple-item) {
      margin-top: -12px !important;
    }
  }

  .content {
    .drop-file-title {
      font-size: #{$font-size-6};
      line-height: #{$line-height-10};
      color: var(--v-neutral-base);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: #{$font-weight-medium};
    }

    .drop-file-subtitle {
      text-align: center;
    }

    .col {
      gap: #{$spacing-s};
    }
  }

  input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &:hover {
    &:not(.no-border) {
      border: 2px solid var(--v-primary-lighten3) !important;

      * {
        color: var(--v-primary-base) !important;
      }
    }

    &:not(.disable-hover-effect) {
      ::v-deep img {
        filter: brightness(0) saturate(100%) invert(46%) sepia(81%) saturate(4958%) hue-rotate(213deg) brightness(96%)
          contrast(98%);
      }
    }
  }

  .file-name-container {
    max-width: 240px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .image-file-background {
    img {
      height: 28px;
    }

    border-radius: 4px;
    border: 1px solid var(--v-neutral-lighten3);
    background: var(--v-white-base);
    display: flex;
    //padding: 4px 4px 4px 24px;
    padding: 4px 4px 4px 4px;
    align-items: center;
    align-content: center;
    gap: #{$spacing-m};
    width: 100%;

    button {
      filter: invert(61%) sepia(6%) saturate(595%) hue-rotate(186deg) brightness(96%) contrast(87%);
    }

    .file-name-container {
      max-width: 150px;
    }
  }
}

.hover-file {
  background: var(--v-primary-lighten4);
  opacity: 0.8;
}

.v-progress-circular {
  margin: 4px;
}

.button-background {
  width: 100%;
  height: 40px;
  background-color: var(--v-primary-base);
  border-radius: 8px;
  color: var(--v-white-base);
}

.label,
.count-info {
  color: var(--v-neutral-base);
  width: fit-content;
}

.label {
  transform: translateY(-18px) translateX(-8px) scale(0.75);
  z-index: 9;
  position: relative;
  background: var(--v-white-base);
  font-size: #{$font-size-2};
  line-height: #{$line-height-13};
}

.count-info {
  margin-left: auto;
  margin-top: -24px;
  font-size: #{$font-size-0};
  line-height: #{$line-height-15};
}

.bordered-container {
  border-radius: 8px;
  border: 2px solid var(--v-scrollbarGray-base);
  padding: 8px;
}

.multiple-input {
  font-size: #{$font-size-2};
  font-weight: 400;
  line-height: #{$line-height-10}; /* 200% */
  gap: 12px !important;
  color: var(--v-neutral-base);
}
</style>
