<template>
  <div class="poi-property-input-container pa-2" :class="{ 'invalid-input': shouldShowError }">
    <div class="property-header">{{ $t(`${translationPath}phone`) }}</div>
    <vue-tel-input
      ref="telInput"
      v-model="phoneInput"
      :default-country="defaultCountry"
      valid-characters-only
      :disabled="isReadOnly"
      @country-changed="onCountryChanged"
      @validate="(data) => onValidate(data)"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @open="onOpen"
    >
      <template #arrow-icon>
        <PtrIcon icon="caret-down" width="10" />
      </template>
    </vue-tel-input>
    <div v-if="shouldShowError" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: { PtrIcon },
  props: {
    value: String,
    isReadOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      translationPath: "contents.mapDesigner.",
      countryCode: undefined,
      phoneInput: this.value,
      isValid: true,
      isAcceptable: true,
      isFirstValidationDone: false,
      isBlured: true,
      isFocusedOnce: false,
      defaultCountry: "US",
      inputElement: undefined
    };
  },
  computed: {
    phoneWithCountryCode() {
      let returnValue = "";
      if (this.phoneInput?.startsWith("+") || !this.countryCode) {
        returnValue = this.phoneInput;
      } else {
        returnValue = `+${this.countryCode.trim()}${this.phoneInput.trim()}`;
      }
      return returnValue;
    },
    errorMessage() {
      return this.$t(`${this.translationPath}notValid`, { propertyName: "Phone number" });
    },
    shouldShowError() {
      return !this.isAcceptable && this.isFocusedOnce;
    }
  },
  watch: {
    phoneWithCountryCode() {
      this.$emit("input", this.phoneWithCountryCode);
    },
    value() {
      this.phoneInput = this.value;
    },
    isAcceptable() {
      this.$emit("valid", this.isAcceptable);
    }
  },
  created() {
    this.setIsAcceptable();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onCountryChanged(country) {
      this.defaultCountry = country.iso2;
      this.countryCode = country.dialCode;
      // Set phoneInput when country changes
      this.phoneInput = this.phoneInput.replace(/^\+.*/, `+${this.countryCode}`);
    },
    onValidate(data) {
      this.isValid = data.valid;
      // discards first validation event
      if (!this.isFirstValidationDone) {
        this.isFirstValidationDone = true;
        // gets input element of vue-tel-input to handle keydown event to prevent page refresh when pressed on enter
        // did not work in created, since this part is also executed once, i put them here
        const inputElement = this.$refs.telInput.$el.querySelector("input");
        document.addEventListener("keydown", this.onKeyDown);
        this.inputElement = inputElement;
        return;
      }

      // onValidate is called initially when rendered, to emit invalid for empty input, this.isAcceptable is set here as well.
      this.setIsAcceptable();
    },
    onInput() {
      // prevents error message & red border before focusing the input
      if (!this.isBlured) {
        this.setIsAcceptable();
        this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      }
    },
    onFocus() {
      // important to discard initially fired inputEvents before focusing on input
      this.isBlured = false;
      // to display error messages after first focus
      this.isFocusedOnce = true;
    },
    onBlur() {
      this.setIsAcceptable();
      this.isBlured = true;
    },
    setIsAcceptable() {
      this.isAcceptable = this.isValid && /^\+[0-9-\s]+$/.test(this.phoneInput);
    },
    onOpen() {
      // I had to do that, because there is no nice way to beat parents's overflow: hidden property
      // That's why I will make it position: fixed, so the parent will be viewport

      //reference element to position the dropdown element
      const referenceEl = document.querySelectorAll("div.vue-tel-input")[0];
      let viewportOffset = referenceEl.getBoundingClientRect();
      let top = viewportOffset.top;
      let left = viewportOffset.left;
      // open event is emitted when display dropdown button is clicked,
      // but dropdown element is not rendered immediately, timeout is to compensate the time between click and render
      setTimeout(() => {
        // Dropdown element to position
        // We need to reposition element, when it has class 'below'
        // We don't have partially appearing problem when it is displayed above the reference element
        const dropdownEl = document.querySelectorAll("ul.vti__dropdown-list.below")[0];
        if (dropdownEl) {
          dropdownEl.style.position = "fixed";
          // offsets are to compensate reference element height & border
          dropdownEl.style.top = `${top + 35}px`;
          dropdownEl.style.left = `${left + 1}px`;
        }
      }, 100);
      // Sorry about that
    },
    onKeyDown(event) {
      // Checks if the keydown event is for the Enter key and the input element has focus
      if (event.key === "Enter" && document.activeElement === this.inputElement) {
        event.preventDefault();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .vue-tel-input {
    border: 2px solid var(--v-scrollbarGray-base);
    box-shadow: none !important;
    border-radius: 8px;

    &:focus-within {
      background-color: var(--v-primary-lighten5) !important;
      border: 2px solid var(--v-primary-lighten3);
      border-radius: 8px;
    }
  }

  .vti__dropdown {
    border-radius: 4px;
    background-color: var(--v-scrollbarGray-base);
  }

  .vti__input {
    overflow: hidden;
    color: var(--v-neutral-darken3);
    text-overflow: ellipsis;
    font-size: #{$font-size-2};
    line-height: #{$line-height-10}; /* 200% */
  }

  .vti__dropdown-list {
    padding: 0;
    width: 248px !important;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-color: var(--v-white-base);
    margin-left: -10px;

    * {
      font-size: #{$font-size-0};
      color: var(--v-neutral-base);
      font-weight: 400;
    }

    li {
      padding-left: 0;
      padding-right: 0;
      max-width: 248px;

      white-space: no-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.invalid-input {
  ::v-deep {
    .vue-tel-input {
      border-color: var(--v-error-lighten2) !important;
    }

    .vti__input {
      color: var(--v-error-base);
    }
  }
}

.error-message {
  color: var(--v-error-base);
  font-size: #{$font-size-2};
  padding: 0 8px;
}

::v-deep .vti__dropdown-list {
  animation: heightTransition 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  z-index: 10;
}

@keyframes heightTransition {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}
</style>
