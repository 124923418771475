<template>
  <v-row no-gutters class="mb-2">
    <v-col class="opening-hour-data" cols="12" sm="2">
      <div>{{ day.substring(0, 3) }}</div>
    </v-col>
    <v-col v-if="!isClosed && !isAlwaysOpen" class="poi-property-input-container mr-1">
      <v-tooltip
        :id="`opening-tooltip-${day.toLowerCase()}`"
        v-click-outside.stop="{
          handler: () => (displayOpeningHourInput = false),
          include: includeOpeningInputArea
        }"
        :value="displayOpeningHourInput"
        bottom
        :open-on-hover="false"
        :disabled="isReadOnly"
        content-class="hoverable-hour-input"
        @click.stop="() => {}"
      >
        <template #activator="{ on, attrs }">
          <div
            :id="`open-input${day.toLowerCase()}`"
            class="clickable-input d-flex align-center justify-space-around"
            v-bind="attrs"
            v-on="on"
            @click.stop="() => (displayOpeningHourInput = true)"
          >
            <div class="opening-hour-data">{{ hours?.[0].substring(0, 5) }}</div>
            <PtrIcon icon="caret-down" />
          </div>
        </template>
        <span>
          <OpeningHoursInput
            :hour="openingHourInt"
            :minute="openingMinuteInt"
            :is-closed="isClosed"
            :is-always-open="isAlwaysOpen"
            @minuteUpdated="(newVal) => updateMinute(newVal, 0)"
            @hourUpdated="(newVal) => updateHour(newVal, 0)"
            @isClosedSwitched="(newVal) => updateIsClosed(newVal)"
            @isAlwaysOpenSwitched="(newVal) => updateIsAlwaysOpen(newVal)"
          />
        </span>
      </v-tooltip>
    </v-col>
    <v-col v-if="!isClosed && !isAlwaysOpen" class="poi-property-input-container">
      <v-tooltip
        :id="`closing-tooltip-${day.toLowerCase()}`"
        v-click-outside.stop="{
          handler: () => (displayClosingHourInput = false),
          include: includeClosingInputArea
        }"
        :value="displayClosingHourInput"
        bottom
        :open-on-hover="false"
        :disabled="isReadOnly"
        content-class="hoverable-hour-input"
        @click.stop="() => {}"
      >
        <template #activator="{ on, attrs }">
          <div
            :id="`close-input${day.toLowerCase()}`"
            class="clickable-input d-flex align-center justify-space-around"
            v-bind="attrs"
            v-on="on"
            @click.stop="() => (displayClosingHourInput = true)"
          >
            <div class="opening-hour-data">{{ hours?.[1].substring(0, 5) }}</div>
            <PtrIcon icon="caret-down" />
          </div>
        </template>
        <span>
          <OpeningHoursInput
            :hour="closingHourInt"
            :minute="closingMinuteInt"
            :is-closed="isClosed"
            :is-always-open="isAlwaysOpen"
            @minuteUpdated="(newVal) => updateMinute(newVal, 1)"
            @hourUpdated="(newVal) => updateHour(newVal, 1)"
            @isClosedSwitched="(newVal) => updateIsClosed(newVal)"
            @isAlwaysOpenSwitched="(newVal) => updateIsAlwaysOpen(newVal)"
          />
        </span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="isClosed"
      class="poi-property-input-container opening-hour-data closed clickable"
      @click="updateIsClosed(isReadOnly)"
    >
      Closed
    </v-col>
    <v-col
      v-if="isAlwaysOpen"
      class="poi-property-input-container opening-hour-data always-open clickable"
      @click="updateIsAlwaysOpen(isReadOnly)"
    >
      Always Open
    </v-col>
  </v-row>
</template>

<script>
import OpeningHoursInput from "@/components/mapDesigner/OpeningHoursInput.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: { OpeningHoursInput, PtrIcon },
  props: {
    value: Array,
    day: String,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.feature.",
    hours: undefined,
    displayOpeningHourInput: false,
    displayClosingHourInput: false
  }),
  computed: {
    isClosed() {
      return !this.hours;
    },
    isAlwaysOpen() {
      return (
        this.openingHourInt === 0 &&
        this.openingMinuteInt === 0 &&
        this.closingHourInt === 0 &&
        this.closingMinuteInt === 0
      );
    },
    openingHourInt() {
      return parseInt(this.hours?.[0].substring(0, 2), 10);
    },
    openingMinuteInt() {
      return parseInt(this.hours?.[0].substring(3, 5), 10);
    },
    closingHourInt() {
      return parseInt(this.hours?.[1].substring(0, 2), 10);
    },
    closingMinuteInt() {
      return parseInt(this.hours?.[1].substring(3, 5), 10);
    }
  },
  watch: {
    hours() {
      this.$emit("hoursUpdated", this.hours);
    },
    value: {
      deep: true,
      handler() {
        this.hours = this.value;
      }
    }
  },
  created() {
    this.hours = this.value;
  },
  methods: {
    updateMinute(newVal, index) {
      const temp = this.hours;
      const hourPart = temp[index].substring(0, 3);
      const timeZonePart = temp[index].substring(5);
      temp[index] = hourPart + newVal.padStart(2, "0") + timeZonePart;
      this.hours = [...temp];
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    updateHour(newVal, index) {
      const temp = this.hours;
      const minutePart = temp[index].substring(2, 5);
      const timeZonePart = temp[index].substring(5);
      temp[index] = newVal.padStart(2, "0") + minutePart + timeZonePart;
      this.hours = [...temp];
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    updateIsClosed(newVal) {
      this.$emit("isClosedSwitched", newVal);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    updateIsAlwaysOpen(newVal) {
      this.$emit("isAlwaysOpenSwitched", newVal);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    // We need to specify the tooltip elements that shouldn't be considered outside of v-tooltip
    // So that clicking outside the tooltip closes it properly and we need to use query selector for that
    includeClosingInputArea() {
      let includedElements = [document.querySelector(`#close-input${this.day.toLowerCase()}`)];
      const tooltipEls = document.querySelectorAll("div.hoverable-hour-input");
      if (tooltipEls?.length) {
        includedElements.push(...tooltipEls);
      }
      return includedElements;
    },
    includeOpeningInputArea() {
      let includedElements = [document.querySelector(`#open-input${this.day.toLowerCase()}`)];
      const tooltipEls = document.querySelectorAll("div.hoverable-hour-input");
      if (tooltipEls?.length) {
        includedElements.push(...tooltipEls);
      }
      return includedElements;
    }
  }
};
</script>
<style lang="scss">
.opening-hour-data {
  color: var(--v-neutral-darken3);
  text-align: center;
  font-size: #{$font-size-2};
  line-height: #{$line-height-10}; /* 200% */
  div {
    line-height: #{$line-height-16};
  }
}

.clickable-input {
  cursor: pointer;
  padding: 4px 8px !important;
}

.closed {
  border-radius: 8px;
  background-color: var(--v-neutral-lighten4) !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
}

.always-open {
  border-radius: 8px;
  background-color: var(--v-neutral-lighten4) !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
}

.hoverable-hour-input {
  pointer-events: initial;
  width: 120px;

  .col {
    width: 50px;
  }

  background-color: var(--v-white-base);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);

  &.menuable__content__active {
    opacity: 1;
  }
}

.hour-min-value {
  border-radius: 4px;
  border: 1px solid var(--v-neutral-lighten3);

  text-align: center;
}

::v-deep {
  .v-tooltip__content {
    z-index: 10 !important;
  }
}
</style>
