<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="geofenceForm" v-model="valid" class="mt-2 mb-3">
          <v-row v-if="shouldShowFeatureName" class="mb-1">
            <v-col class="py-0">
              <v-text-field
                id="name-input"
                v-model.trim="name"
                :rules="[rules.featureName, rules.characterValidation]"
                :label="$t(featureTranslationPath + 'name-required')"
                hide-details="auto"
                outlined
                dense
                :disabled="isReadOnly"
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row v-if="shouldShowFeatureDescription" class="mt-0 mb-1">
            <v-col class="py-0">
              <v-textarea
                id="description-input"
                v-model="description"
                :label="$t(featureTranslationPath + 'description')"
                :rules="[rules.featureDescription]"
                hide-details="auto"
                outlined
                dense
                rows="1"
                auto-grow
                :disabled="isReadOnly"
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-1">
            <v-col class="py-0">
              <v-text-field
                id="eid-input"
                v-model="eid"
                :label="$t(featureTranslationPath + 'eid')"
                hide-details="auto"
                outlined
                dense
                :disabled="isReadOnly"
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <GeometrySection
            v-if="!isReadOnly"
            ref="geometrySection"
            :feature-id="featureId"
            :should-show-polygon-icon="true"
            :should-show-point-icon="false"
            :is-edit="isEdit"
          ></GeometrySection>
        </v-form>
        <div class="form-footer paragraph-xs mt-5">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          :is-read-only="isReadOnly"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ContentService from "@/services/ContentService";
import MapModeConstants from "@/constants/mapModeConstants";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MapHelpers from "@/helpers/MapHelpers";

const DISPLAY_MODE_UNAVAILABLE = "unavailable";
const DISPLAY_MODE_REQUIRED = "required";

export default {
  components: { GeometrySection, CustomIntegration, PtrIcon },
  props: {
    featureId: [String, Number],
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    selectedType: "level-geofence",
    featureNameDisplayMode: "",
    featureDescriptionDisplayMode: "",
    taxonomyType: "",
    name: undefined,
    description: "",
    extraData: {},
    eid: "",
    valid: false,
    isEdit: false,
    defaultOpenPanelIndex: 0
  }),
  computed: {
    ...mapState("CONTENT", ["levelGeofences", "poiTypeCodesObj", "isFormDirty"]),
    ...mapState("MAP", [
      "mapDraw",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "drawnCoordinates",
      "isMapBorderEnabled"
    ]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    shouldShowFeatureName() {
      return this.featureNameDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    shouldShowFeatureDescription() {
      return this.featureDescriptionDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    rules() {
      return {
        featureName: (value) => {
          return ValidationHelpers.isRequired(value?.trim());
        },
        featureDescription: (value) => {
          return this.featureDescriptionDisplayMode === DISPLAY_MODE_REQUIRED
            ? ValidationHelpers.isRequired(value)
            : true;
        },
        required: (value) => ValidationHelpers.isRequired(value),
        characterValidation: (value) => {
          return ValidationHelpers.isNameLengthValid(value);
        }
      };
    }
  },
  watch: {
    featureId: {
      immediate: true,
      handler() {
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        this.feature = this.levelGeofences.find((geofence) => geofence.properties.fid === this.featureId);
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        if (this.feature) {
          this.isEdit = true;
          this.name = this.feature.properties.name;
          this.description = this.feature.properties.description;
          this.eid = this.feature.properties.eid;
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.taxonomyType = this.taxonomy[this.selectedType];
          this.$refs?.geometrySection?.reset();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.feature.geometry.coordinates);
          this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: this.feature, shouldKeepFormClean: true });
        } else {
          this.$refs.geofenceForm?.resetValidation();
          this.isEdit = false;
          this.name = "";
          this.description = "";
          this.eid = "";
          this.extraData = {};
          this.taxonomyType = "";
        }
      }
    },
    valid() {
      this.emitValidationStatusForForm();
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.$store.dispatch("MAP/UNHIGHLIGHT_ALL_FEATURES");
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.parseTypeForInputs();
    this.$store.dispatch("MAP/MAP_ENTER_EDIT_MODE");
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.dispatch("MAP/MAP_EXIT_EDIT_MODE");
  },
  methods: {
    draw() {
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAW_POLYGON);
    },
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }

      const isCoordinatesPolygon = Array.isArray(coordinates?.[0]);
      const isMultiPolygon = this.mapDraw.getAll()?.features?.[0]?.geometry?.type === "MultiPolygon";
      const geometryType = isCoordinatesPolygon ? "Polygon" : "Point";

      if (isMultiPolygon) {
        let isSuccess;
        await Promise.all(
          coordinates.map(async (coordinate, index) => {
            if (index === 0) {
              isSuccess = await this.createRequestAndPut(
                this.feature?.properties?.fid,
                this.name,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                true
              );
            } else {
              isSuccess = await this.createRequestAndPut(
                undefined,
                this.name + "#" + index,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                false
              );
            }
          })
        );
        return isSuccess;
      } else {
        return await this.createRequestAndPut(
          this.feature?.properties?.fid,
          this.name,
          {
            type: geometryType,
            coordinates: coordinates
          },
          true
        );
      }
    },

    async createRequestAndPut(fid, name, geometry, isEdit) {
      let extraData = this.$refs?.customIntegration?.getExtraData();
      if (!extraData && this.feature) {
        extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
      }
      const request = {
        type: "Feature",
        properties: {
          typeCode: this.selectedType,
          fid: fid,
          eid: this.eid?.trim(),
          sid: Number(this.currentSite),
          bid: Number(this.currentBuilding),
          lvl: Number(this.currentLevel),
          name: name?.trim(),
          description: this.description?.trim(),
          keywords: this.keywords || [],
          extra: extraData,
          style: {}
        },
        geometry: geometry
      };
      const response = await ContentService.putFeature(
        request,
        ContentService.CONTENT_TYPES.LEVEL_GEOFENCE,
        this.isEdit
      );

      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: request, isEdit: this.isEdit && isEdit });
        if (this.levelGeofences?.length) {
          this.$store.dispatch("MAP/ADD_GEOFENCES_TO_MAP");
        }
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },

    async deleteContent() {
      const response = await ContentService.deleteFeature(this.feature);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: this.feature, isDelete: true });
        this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },
    parseTypeForInputs() {
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
      this.featureDescriptionDisplayMode = this.taxonomyType?.properties?.isDescriptionEnabled;
    },
    emitValidationStatusForForm() {
      this.$emit(
        "valid",
        this.valid && this.drawnCoordinates && this.drawnCoordinates?.length !== 0 && !this.isMapBorderEnabled
      );
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
<style lang="scss" scoped>
div.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  width: 200px;
}
</style>
