<template>
  <div class="modal-overlay" @click="goBack">
    <div class="modal-content py-3 px-6" @click.stop>
      <v-card-title class="delete-content-modal-title d-flex flex-column align-center pb-1">
        <PtrIcon icon="delete-alert" height="36" class="mb-3" />
        {{ $t(`${translationPath}title`) }}
      </v-card-title>
      <v-card-subtitle class="mt-1 px-0 pb-2 delete-modal-subtitle"> {{ $t(descriptionPath) }} </v-card-subtitle>
      <v-card-text class="px-0">
        <div v-if="type.length === 0" class="warning-container">
          <div class="warning-header paragraph-s" color="error">{{ $t(`${translationPath}warning`) }}</div>
          <div class="warning-description paragraph-xs">{{ $t(`${translationPath}warning-description`) }}</div>
        </div>
      </v-card-text>
      <v-row dense>
        <v-col>
          <v-btn id="delete-content-modal-primary-action-btn" color="error" @click="confirm">
            {{ $t(`${translationPath}confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn
            id="delete-content-modal-go-back-action-btn"
            class="delete-content-modal-dismiss"
            color="white"
            small
            @click="goBack"
          >
            {{ $t(`${translationPath}cancel`) }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  name: "DeleteContentModal",
  components: { PtrIcon },
  props: {
    type: { type: String, default: "" }
  },
  data: () => ({
    translationPath: "contents.deleteContentModal."
  }),

  computed: {
    descriptionPath() {
      if (this.type.length > 0) {
        return `${this.translationPath}description-${this.type}`;
      }
      let contentType = this.$route.params.contentType;
      if (contentType === "wayfinding-network" || contentType === "outdoor-wayfinding-network") {
        const wayfindingContentType = this.$route.params.featureId;
        contentType = wayfindingContentType.substring(wayfindingContentType.indexOf("-") + 1);
      }
      return `${this.translationPath}description-${contentType}`;
    }
  },

  methods: {
    goBack() {
      this.$emit("goBack");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  // Left menu width is 64px so we put overlay left to cover
  position: fixed;
  min-height: 100% !important;
  .modal-content {
    width: 320px;

    .delete-content-modal-title {
      justify-content: center;
      color: var(--v-error-base);
      font-size: #{$font-size-8} !important;
      line-height: 32px;
      font-weight: #{$font-weight-medium};
    }

    .delete-content-modal-dismiss {
      color: var(--v-neutral-darken1);
      font-weight: 400;
    }
    .delete-modal-subtitle {
      color: var(--v-neutral-darken5);
    }

    .warning-container {
      background-color: var(--v-error-lighten3);
      width: max-content;
      margin: auto;
      padding: 8px;
      border-radius: 8px;
      .warning-header {
        color: var(--v-error-base);
      }
      .warning-description {
        font-weight: #{$font-weight-regular};
        color: var(--v-neutral-darken3);
      }
    }
  }
}
</style>
