import Helpers from "@/helpers/Helpers";

export default [
  {
    path: "metadata",
    name: "Metadata",
    components: {
      default: Helpers.lazyLoad("MetadataView", "views/config")
    },
    meta: { requiresAuth: true }
  },
  {
    path: "configuration",
    name: "Configuration",
    components: {
      default: Helpers.lazyLoad("ConfigurationView", "views/config")
    },
    meta: { requiresAuth: true }
  },
  {
    path: "users-and-permissions/:userId",
    name: "UserEdit",
    component: Helpers.lazyLoad("UserAddEditView", "views/config"),
    meta: { requiresAuth: true }
  },
  {
    path: "users-and-permissions/:userId(add)",
    name: "UserAdd",
    component: Helpers.lazyLoad("UserAddEditView", "views/config"),
    meta: { requiresAuth: true }
  },
  {
    path: "users-and-permissions",
    name: "UsersAndPermissions",
    components: {
      default: Helpers.lazyLoad("UserRolesView", "views/config")
    },
    meta: { requiresAuth: true }
  },
  {
    path: "content",
    name: "ContentConfiguration",
    components: {
      default: Helpers.lazyLoad("ContentView", "views/config")
    },
    meta: { requiresAuth: true }
  }
];
