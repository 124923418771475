var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"opening-hour-data",attrs:{"cols":"12","sm":"2"}},[_c('div',[_vm._v(_vm._s(_vm.day.substring(0, 3)))])]),(!_vm.isClosed && !_vm.isAlwaysOpen)?_c('v-col',{staticClass:"poi-property-input-container mr-1"},[_c('v-tooltip',{directives:[{name:"click-outside",rawName:"v-click-outside.stop",value:({
        handler: () => (_vm.displayOpeningHourInput = false),
        include: _vm.includeOpeningInputArea
      }),expression:"{\n        handler: () => (displayOpeningHourInput = false),\n        include: includeOpeningInputArea\n      }",modifiers:{"stop":true}}],attrs:{"id":`opening-tooltip-${_vm.day.toLowerCase()}`,"value":_vm.displayOpeningHourInput,"bottom":"","open-on-hover":false,"disabled":_vm.isReadOnly,"content-class":"hoverable-hour-input"},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"clickable-input d-flex align-center justify-space-around",attrs:{"id":`open-input${_vm.day.toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();return (() => (_vm.displayOpeningHourInput = true)).apply(null, arguments)}}},'div',attrs,false),on),[_c('div',{staticClass:"opening-hour-data"},[_vm._v(_vm._s(_vm.hours?.[0].substring(0, 5)))]),_c('PtrIcon',{attrs:{"icon":"caret-down"}})],1)]}}],null,false,1300774960)},[_c('span',[_c('OpeningHoursInput',{attrs:{"hour":_vm.openingHourInt,"minute":_vm.openingMinuteInt,"is-closed":_vm.isClosed,"is-always-open":_vm.isAlwaysOpen},on:{"minuteUpdated":(newVal) => _vm.updateMinute(newVal, 0),"hourUpdated":(newVal) => _vm.updateHour(newVal, 0),"isClosedSwitched":(newVal) => _vm.updateIsClosed(newVal),"isAlwaysOpenSwitched":(newVal) => _vm.updateIsAlwaysOpen(newVal)}})],1)])],1):_vm._e(),(!_vm.isClosed && !_vm.isAlwaysOpen)?_c('v-col',{staticClass:"poi-property-input-container"},[_c('v-tooltip',{directives:[{name:"click-outside",rawName:"v-click-outside.stop",value:({
        handler: () => (_vm.displayClosingHourInput = false),
        include: _vm.includeClosingInputArea
      }),expression:"{\n        handler: () => (displayClosingHourInput = false),\n        include: includeClosingInputArea\n      }",modifiers:{"stop":true}}],attrs:{"id":`closing-tooltip-${_vm.day.toLowerCase()}`,"value":_vm.displayClosingHourInput,"bottom":"","open-on-hover":false,"disabled":_vm.isReadOnly,"content-class":"hoverable-hour-input"},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"clickable-input d-flex align-center justify-space-around",attrs:{"id":`close-input${_vm.day.toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();return (() => (_vm.displayClosingHourInput = true)).apply(null, arguments)}}},'div',attrs,false),on),[_c('div',{staticClass:"opening-hour-data"},[_vm._v(_vm._s(_vm.hours?.[1].substring(0, 5)))]),_c('PtrIcon',{attrs:{"icon":"caret-down"}})],1)]}}],null,false,413987124)},[_c('span',[_c('OpeningHoursInput',{attrs:{"hour":_vm.closingHourInt,"minute":_vm.closingMinuteInt,"is-closed":_vm.isClosed,"is-always-open":_vm.isAlwaysOpen},on:{"minuteUpdated":(newVal) => _vm.updateMinute(newVal, 1),"hourUpdated":(newVal) => _vm.updateHour(newVal, 1),"isClosedSwitched":(newVal) => _vm.updateIsClosed(newVal),"isAlwaysOpenSwitched":(newVal) => _vm.updateIsAlwaysOpen(newVal)}})],1)])],1):_vm._e(),(_vm.isClosed)?_c('v-col',{staticClass:"poi-property-input-container opening-hour-data closed clickable",on:{"click":function($event){return _vm.updateIsClosed(_vm.isReadOnly)}}},[_vm._v(" Closed ")]):_vm._e(),(_vm.isAlwaysOpen)?_c('v-col',{staticClass:"poi-property-input-container opening-hour-data always-open clickable",on:{"click":function($event){return _vm.updateIsAlwaysOpen(_vm.isReadOnly)}}},[_vm._v(" Always Open ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }