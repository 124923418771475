<template>
  <v-row :class="{ 'editing-geometry': isMapBorderEnabled }" class="d-flex flex-column mt-4">
    <v-col class="pt-0">
      <div class="content-add-edit-subtitle mb-1">{{ $t(`${translationPath}geometry`) }}</div>
      <slot name="auto-generate"></slot>
      <div v-if="(!isDrawingFinished && geometryAction !== 'modify') || (!drawnCoordinates && !isPath)">
        <div class="pb-2 geometry-sub-title">
          {{
            isMapBorderEnabled ? $t(`${translationPath}geometry-guidance`) : $t(`${translationPath}geometry-sub-base`)
          }}
        </div>
        <v-btn-toggle v-model="drawMode" class="mt-3">
          <div
            v-if="shouldShowPolygonIcon"
            class="geometry-button-container mr-2"
            :class="{ selected: drawMode === 'Rectangle' }"
          >
            <v-btn
              value="Rectangle"
              class="geometry-button mb-1"
              :color="drawMode === 'Rectangle' ? 'primary' : 'white'"
              :disabled="isRectangleDisabled"
            >
              <PtrIcon icon="draw-rect" :color="drawMode === 'Rectangle' ? '#FFFFFF' : '#9095A2'" editable-color />
            </v-btn>
            {{ $t(`${translationPath}rectangle`) }}
          </div>
          <div
            v-if="shouldShowPolygonIcon"
            class="geometry-button-container mr-2"
            :class="{ selected: drawMode === 'Polygon' }"
          >
            <v-btn
              value="Polygon"
              class="geometry-button mb-1"
              :color="drawMode === 'Polygon' ? 'primary' : 'white'"
              :disabled="isPolygonDisabled"
            >
              <PtrIcon icon="draw-polygon" :color="drawMode === 'Polygon' ? '#FFFFFF' : '#9095A2'" editable-color />
            </v-btn>
            {{ $t(`${translationPath}polygon`) }}
          </div>
          <div
            v-if="isCircleEnabled"
            class="geometry-button-container mr-2"
            :class="{ selected: drawMode === 'Circle' }"
          >
            <v-btn
              value="Circle"
              class="geometry-button mb-1"
              :color="drawMode === 'Circle' ? 'primary' : 'white'"
              :disabled="isCircleDisabled"
            >
              <PtrIcon icon="draw-circle" />
            </v-btn>
            {{ $t(`${translationPath}circle`) }}
          </div>
          <div v-if="shouldShowPointIcon" class="geometry-button-container" :class="{ selected: drawMode === 'Point' }">
            <v-btn
              value="Point"
              class="geometry-button mb-1"
              :color="drawMode === 'Point' ? 'primary' : 'white'"
              :disabled="isPointDisabled"
            >
              <PtrIcon icon="draw-marker" :color="drawMode === 'Point' ? '#FFFFFF' : '#9095A2'" editable-color />
            </v-btn>
            {{ $t(`${translationPath}point`) }}
          </div>
          <div v-if="isPath" class="geometry-button-container mr-2" :class="{ selected: drawMode === 'Path' }">
            <v-btn
              value="Path"
              class="geometry-button mb-1"
              :color="drawMode === 'Path' ? 'primary' : 'white'"
              @click="onPathClicked"
            >
              <PtrIcon icon="path" :color="drawMode === 'Path' ? '#FFFFFF' : '#9095A2'" editable-color />
            </v-btn>
            {{ $t(`${translationPath}path`) }}
          </div>
        </v-btn-toggle>
      </div>
      <div v-else>
        <div class="pb-2 geometry-sub-title">{{ $t(`${translationPath}geometry-sub-alternative`) }}</div>
        <v-btn-toggle v-model="geometryAction" class="mt-3">
          <div
            v-if="!shouldDisableModify"
            class="geometry-button-container mr-2"
            :class="{ selected: geometryAction === 'modify' }"
          >
            <v-btn
              value="modify"
              class="geometry-button mb-1"
              :class="{ 'disable-events': geometryAction }"
              :color="geometryAction === 'modify' ? 'primary' : 'white'"
              :disabled="isModifyDisabled"
              @click="editGeometry"
            >
              <PtrIcon
                icon="modify-geometry"
                :color="geometryAction === 'modify' ? '#FFFFFF' : '#9095A2'"
                editable-color
              />
            </v-btn>
            {{ $t(`${translationPath}modify`) }}
          </div>
          <div
            v-if="!shouldDisableCut && shouldDisplayCutIcon"
            class="geometry-button-container mr-2"
            :class="{ selected: geometryAction === 'cut' }"
          >
            <v-btn
              value="cut"
              class="geometry-button mb-1"
              :class="{ 'disable-events': geometryAction }"
              :color="geometryAction === 'cut' ? 'primary' : 'white'"
              :disabled="isCutDisabled"
              @click="onCutClicked"
            >
              <PtrIcon icon="draw-cut" :color="geometryAction === 'cut' ? '#FFFFFF' : '#9095A2'" editable-color />
            </v-btn>
            {{ $t(`${translationPath}cut-out`) }}
          </div>
          <div class="geometry-button-container apply-color-filter">
            <v-btn
              value="clear"
              class="geometry-button mb-1"
              :class="{ 'disable-events': geometryAction }"
              color="white"
              :disabled="isClearDisabled"
              @click="onClearClicked"
            >
              <PtrIcon icon="clear-geometry" />
            </v-btn>
            {{ $t(`${translationPath}clear`) }}
          </div>
        </v-btn-toggle>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MapModeConstants from "@/constants/mapModeConstants";
import PointMode from "@/helpers/drawModes/PointMode";
import MapHelpers from "@/helpers/MapHelpers";

export default {
  components: { PtrIcon },
  props: {
    shouldShowPolygonIcon: Boolean,
    shouldShowPointIcon: Boolean,
    isEdit: Boolean,
    isCircleEnabled: { type: Boolean, default: false },
    shouldDisableCut: { type: Boolean, default: false },
    shouldDisableModify: { type: Boolean, default: false },
    featureId: [String, Number],
    isPath: { type: Boolean, default: false }
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    drawMode: undefined,
    editGeometryClicked: false,
    isDrawingFinished: false,
    geometryAction: undefined,
    clickCount: 0
  }),
  computed: {
    ...mapState("MAP", ["map", "mapDraw", "drawnCoordinates", "mapMode", "isMapBorderEnabled", "isAddEditPanelActive"]),
    isRectangleDisabled() {
      return this.clickCount > 0 && this.drawMode !== "Rectangle" && this.drawMode !== undefined;
    },
    isPolygonDisabled() {
      return this.clickCount > 0 && this.drawMode !== "Polygon" && this.drawMode !== undefined;
    },
    isPointDisabled() {
      return this.clickCount > 0 && this.drawMode !== "Point" && this.drawMode !== undefined;
    },
    isCircleDisabled() {
      return this.clickCount > 0 && this.drawMode !== "Circle" && this.drawMode !== undefined;
    },
    isModifyDisabled() {
      return this.geometryAction !== undefined && this.geometryAction !== "modify";
    },
    isCutDisabled() {
      return this.geometryAction !== undefined && this.geometryAction !== "cut";
    },
    isClearDisabled() {
      return this.geometryAction !== undefined && this.geometryAction !== "clear";
    },
    shouldDisplayCutIcon() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      return Array.isArray(coordinates[0]);
    }
  },
  watch: {
    featureId() {
      this.editGeometryClicked = false;
      if (this.isEdit) {
        this.isDrawingFinished = true;
      }
    },
    drawMode() {
      this.handleDrawMode();
    },
    drawnCoordinates() {
      if (this.drawnCoordinates && !this.isDrawingFinished) {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
      }
    },
    geometryAction() {
      this.drawMode = undefined;
    }
  },
  created() {
    this.map.on("draw.selectionchange", this.mapDrawSelectedFromMap);
    this.map.on("contextmenu", this.mapRightClicked);
    this.map.on("draw.modechange", this.drawModeChanged);
    this.map.on("click", this.mapClicked);
    this.map.on("mouseup", this.onMouseUp);
    if (this.isEdit) {
      this.isDrawingFinished = true;
    }
  },
  beforeDestroy() {
    this.cancelClicked();
    this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
    this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
    this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    this.map.off("contextmenu", this.mapRightClicked);
    this.map.off("click", this.mapClicked);
    this.map.off("mouseup", this.onMouseUp);
    this.map.off("draw.selectionchange", this.mapDrawSelectedFromMap);
    this.map.off("draw.modechange", this.drawModeChanged);
  },
  methods: {
    onPathClicked() {
      this.isDrawingFinished = false;
      this.drawMode = "Path";
      this.$emit("pathClicked");
    },
    handleDrawMode() {
      if (!this.drawnCoordinates) {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.start-drawing"));
        if (this.drawMode) {
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
        }
      }
      let pointCoordinates;
      PointMode.toggle({ shouldEnable: false });
      switch (this.drawMode) {
        case "Polygon":
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAW_POLYGON);
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
          break;
        case "Point":
          try {
            pointCoordinates = JSON.parse(this.drawnCoordinates);
          } catch (e) {
            pointCoordinates = this.drawnCoordinates;
          }
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAW_POINT);
          PointMode.addPoint(pointCoordinates, {
            draggable: true,
            fid: this.featureId
          });
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
          break;
        case "Rectangle":
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAW_RECTANGLE);
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
          break;
        case "Circle":
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAG_CIRCLE);
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
          break;
        case "Path":
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
          break;
        default:
          this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
          this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
          this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
          this.mapRightClicked();
      }
    },

    editGeometry() {
      this.editGeometryClicked = true;
      this.isDrawingFinished = false;
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
      if (this.isPath) {
        this.$emit("editGeometry");
        return;
      }
      const isCoordinatesPolygon = Array.isArray(coordinates?.[0]);
      if (!isCoordinatesPolygon) {
        this.$store.commit("MAP/MAP_MODE", MapModeConstants.DRAW_POINT);
        MapHelpers.fitAroundPoint(
          coordinates,
          this.isAddEditPanelActive
            ? {
                padding: {
                  top: 250,
                  bottom: 250,
                  left: 90,
                  right: 410 // Left panel width is 320px
                }
              }
            : undefined
        );
      } else {
        this.mapDraw.changeMode("direct_select", { featureId: this.mapDraw.getAll()?.features?.[0]?.id });
        MapHelpers.fitBounds(
          coordinates?.[0],
          this.isAddEditPanelActive
            ? {
                padding: {
                  top: 250,
                  bottom: 250,
                  left: 90,
                  right: 410 // Left panel width is 320px
                }
              }
            : undefined
        );
      }
    },
    mapDrawSelectedFromMap(event) {
      if (event.features.length && !this.isMapBorderEnabled) {
        this.geometryAction = "modify";
        this.editGeometry();
      }
    },
    onClearClicked() {
      this.clear();
      this.$emit("clear");
    },
    clear() {
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
      this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
      this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
      PointMode.clearPoints();
      this.drawMode = undefined;
      this.geometryAction = undefined;
      this.isDrawingFinished = false;
    },
    cancelClicked() {
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
      this.drawMode = undefined;
      this.clear();
    },
    mapRightClicked() {
      if (this.isMapBorderEnabled) {
        this.isDrawingFinished = true;
        this.editGeometryClicked = false;
        this.drawMode = undefined;
        this.clickCount = 0;
        this.geometryAction = undefined;
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
        this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
      }
    },
    mapClicked() {
      if (this.drawMode !== "Circle" && (this.drawMode || this.geometryAction === "cut")) {
        this.clickCount += 1;
        if (this.geometryAction === "cut" || this.drawMode === "Polygon") {
          if (this.clickCount === 1) {
            this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
          } else if (this.geometryAction === "cut" && this.clickCount === 3) {
            this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-cutting"));
          } else if (this.drawMode === "Polygon" && this.clickCount === 3) {
            this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
          }
        }
      }
      if (this.geometryAction === "cut" && this.mapMode !== MapModeConstants.CUT_POLYGON) {
        this.$store.commit("MAP/MAP_MODE", MapModeConstants.CUT_POLYGON);
      }
      if (
        this.editGeometryClicked &&
        !this.isDrawingFinished &&
        this.mapDraw.getAll()?.features?.[0]?.id !== undefined
      ) {
        this.mapDraw.changeMode("direct_select", { featureId: this.mapDraw.getAll()?.features?.[0]?.id });
      }
    },
    /**
     * This method is called when the draw mode is changed
     * Sometimes mapclick isn't enough to keep mode in direct select due to bug on mapDraw
     * This method is a workaround for that bug
     * We're changing it to direct select mode when draw mode is changed
     */
    drawModeChanged() {
      // set timeout is used to wait for right click event. o.w draw mode changed fires first
      setTimeout(() => {
        if (this.editGeometryClicked && !this.isDrawingFinished && !this.isDrawingFinished) {
          this.mapDraw.changeMode("direct_select", { featureId: this.mapDraw.getAll()?.features?.[0]?.id });
        }
      });
    },
    onMouseUp() {
      if (this.clickCount === 0 && this.drawMode === "Circle" && this.drawnCoordinates) {
        this.clickCount += 1;
      }
    },
    onCutClicked() {
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.start-cutting"));
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.CUT_POLYGON);
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      MapHelpers.fitBounds(
        coordinates?.[0],
        this.isAddEditPanelActive
          ? {
              padding: {
                top: 250,
                bottom: 250,
                left: 90,
                right: 410 // Left panel width is 320px
              }
            }
          : undefined
      );
    },
    reset() {
      this.drawMode = undefined;
      this.editGeometryClicked = false;
      this.isDrawingFinished = false;
      this.geometryAction = undefined;
      this.clickCount = 0;
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
      this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
    }
  }
};
</script>

<style lang="scss" scoped>
.editing-geometry {
  background: var(--v-primary-lighten4);
}
</style>
