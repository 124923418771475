<template>
  <div class="empty-list px-4">
    <v-row>
      <v-col
        class="d-flex align-center justify-start"
        :class="vertical ? 'flex-column gap-s py-4' : 'empty-list-line py-5 py-2'"
      >
        <PtrIcon :icon="iconName" :width="iconWidth" :color="iconColor" :editable-color="!!iconColor" class="my-2" />
        <div v-if="!hasContentSlot">
          <p class="mb-0">{{ message }}</p>
          <p v-if="!shouldPreventAddNew" class="mb-0" :class="addNewLineClass">
            <v-btn
              class="add-new-button paragraph-s px-0"
              color="primary darken3"
              text
              :disabled="shouldDisableAddNew"
              @click="onAddNewClicked"
            >
              {{ $t(`addNew`) }}
            </v-btn>
            <span>{{ $t("to-get-started") }}</span>
          </p>
        </div>
        <div class="empty-content-header">
          <slot name="header"></slot>
        </div>
        <div class="empty-content-description paragraph-xs">
          <slot name="description"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon";

export default {
  name: "EmptyList",
  components: { PtrIcon },
  props: {
    message: String,
    iconName: {
      type: String,
      default: "alert-icon"
    },
    iconColor: String,
    vertical: {
      type: Boolean,
      default: false
    },
    iconWidth: {
      type: [Number, String]
    },
    shouldDisableAddNew: {
      type: Boolean,
      default: false
    },
    shouldDisplayAddNew: {
      type: Boolean,
      default: true
    },
    addNewLineClass: {
      type: String,
      default: "empty-list-line"
    }
  },
  computed: {
    ...mapState("CONTENT", ["dashboardMode"]),
    hasContentSlot() {
      return !!this.$slots.header || !!this.$slots.description;
    },
    shouldPreventAddNew() {
      return (
        (this.dashboardMode === "embed" && (this.$route.name === "Buildings" || this.$route.name === "Levels")) ||
        !this.shouldDisplayAddNew
      );
    }
  },
  methods: {
    onAddNewClicked() {
      this.$emit("addNew");
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-list {
  padding: 16px;
  background-color: var(--v-primary-lighten5);
  border-radius: 8px;
  color: var(--v-primary-base);

  p,
  .add-new-button {
    font-weight: #{$font-weight-semi-bold} !important;
  }
}

.empty-list-line {
  gap: 20px;

  .custom-icon {
    margin: 0 !important;
  }
}

.add-new-button {
  min-height: 18px !important;
  margin-top: -3px;

  ::v-deep span {
    text-decoration: underline;
  }
}

.empty-content-header {
  font-weight: #{$font-weight-medium};
  font-size: #{$font-size-6};
  line-height: #{$line-height-10};
  text-align: center;
}

.empty-content-header,
.empty-content-description {
  text-align: center;
}

::v-deep .theme--light.v-btn.v-btn--disabled {
  color: var(--v-primary-base) !important;

  span {
    text-decoration: none;
  }
}
</style>
