import maplibregl from "maplibre-gl";
import Helpers from "@/helpers/Helpers";
import store from "@/store/store.js";

import {
  source,
  dashedLineLayer,
  graphLayer,
  snappedNodeLayer,
  customMarker,
  customTransitionMarker,
  createPopupButton,
  createPopupElem
} from "@/helpers/StyleHelpers";
import distance from "@turf/distance";
import { point } from "@turf/helpers";

export default class GraphMode {
  static init() {
    this.isEnabled = false;
    this.nodes = {};
    this.graphData = {
      type: "FeatureCollection",
      features: []
    };
    this.dashedLineData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: []
          }
        }
      ]
    };
    this.snapData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: []
          }
        }
      ]
    };
    this.markers = {};
    this.snapNode = undefined;
    this.prevNodeId = undefined;
    this.middlepointMarkers = {};
    this.cursorSnapDistance = 40;
    this.selectedNode = undefined;
    this.events = ["graph.nodeSelected", "graph.create", "graph.update", "graph.delete", "graph.transitionClicked"];
    this.transitionWarningPopups = {};
    this.clearPopups();
  }

  static generateGraph() {
    if (!this.map) {
      return;
    }
    Object.values(this.markers).forEach((marker) => {
      marker.remove();
    });
    Object.values(this.middlepointMarkers).forEach((marker) => {
      marker.remove();
    });
    this.graphData = {
      type: "FeatureCollection",
      features: []
    };
    this.markers = {};
    this.middlepointMarkers = {};
    Object.values(this.nodes).forEach((node) => (node.isVisited = false));
    this.lineIndex = 0;
    Object.values(this.nodes).forEach((node) => this.generateLines(node));
    this.clearPopups();
    this.generateMarkers();
    this.renderGraph();
  }

  static on(eventName, callback) {
    if (!this.callbacks) {
      this.callbacks = {};
      this.events.forEach((e) => (this.callbacks[e] = []));
    }
    if (!this.callbacks[eventName]) {
      console.debug("Invalid event name", eventName);
      return;
    }
    if (typeof callback !== "function") {
      console.debug("Invalid callback", callback);
      return;
    }
    this.callbacks[eventName].push(callback);
  }

  static off(eventName, callback) {
    this.callbacks[eventName].splice(this.callbacks[eventName].indexOf(callback), 1);
  }

  static create({ map, sid, bid, lvl, taxonomy }) {
    this.init();
    this.map = map;
    this.sid = Number(sid);
    this.bid = Number(bid);
    this.lvl = Number(lvl);
    this.taxonomy = taxonomy;
    this.createSourceAndLayers();
    this.clearEvents();

    // To persist binding of GraphMode as this after passing to map
    this.mapClick = this.mapClick.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.middlepointClickHandler = this.middlepointClickHandler.bind(this);
  }

  static clear(options) {
    if (!this.map) {
      return;
    }
    Object.values(this.markers).forEach((marker) => {
      marker.remove();
    });
    Object.values(this.middlepointMarkers).forEach((marker) => {
      marker.remove();
    });
    this.init();

    this.setSnapData();
    this.setDashedLine();
    this.renderGraph();
    if (options?.shouldFireDeleteEvent) {
      this.callbacks["graph.delete"].forEach((callback) => callback(this.nodes, "deleted"));
    }
  }

  static clearEvents() {
    this.events.forEach((e) => (this.callbacks[e] = []));
  }

  // Not used anywhere
  static removeSourceAndLayers() {
    if (this.map.getLayer(dashedLineLayer.id)) {
      this.map.removeLayer(dashedLineLayer.id);
    }
    if (this.map.getSource(dashedLineLayer.source)) {
      this.map.removeSource(dashedLineLayer.source);
    }

    if (this.map.getLayer(graphLayer.id)) {
      this.map.removeLayer(graphLayer.id);
    }
    if (this.map.getSource(graphLayer.source)) {
      this.map.removeSource(graphLayer.source);
    }

    if (this.map.getLayer(snappedNodeLayer.id)) {
      this.map.removeLayer(snappedNodeLayer.id);
    }
    if (this.map.getSource(snappedNodeLayer.source)) {
      this.map.removeSource(snappedNodeLayer.source);
    }
  }

  static createSourceAndLayers() {
    if (!this.map.getSource(dashedLineLayer.source)) {
      this.map.addSource(dashedLineLayer.source, source(this.dashedLineData));
    }
    if (!this.map.getLayer(dashedLineLayer.id)) {
      this.map.addLayer(dashedLineLayer);
    }
    if (!this.map.getSource(graphLayer.source)) {
      this.map.addSource(graphLayer.source, source(this.graphData));
    }
    if (!this.map.getLayer(graphLayer.id)) {
      this.map.addLayer(graphLayer);
    }
    if (!this.map.getSource(snappedNodeLayer.source)) {
      this.map.addSource(snappedNodeLayer.source, source(this.snapData));
    }
    if (!this.map.getLayer(snappedNodeLayer.id)) {
      this.map.addLayer(snappedNodeLayer);
    }
  }

  static mapClick(e) {
    if (!this.isEnabled) {
      return;
    }
    const { lng, lat } = e.lngLat;
    const isSnapDataExists = this.snapData.features[0].geometry.coordinates.length;

    if (isSnapDataExists && this.snapNode) {
      document.getElementsByClassName(`node-marker-${this.snapNode?.id}`)[0].click();
      return;
    }
    if (
      this.prevNodeId &&
      this.nodes?.[this.prevNodeId]?.coordinate?.[0]?.toFixed(14) === lng?.toFixed(14) &&
      this.nodes?.[this.prevNodeId]?.coordinate?.[1]?.toFixed(14) === lat?.toFixed(14)
    ) {
      return;
    }

    this.createNode([lng, lat]);
    this.setDashedLine([lng, lat]);
    this.generateGraph();
    this.toggleMiddlePoints(true);
  }

  static populateGraphDataWithLines = (node, neighborNode) => {
    if (!this.graphData.features[this.lineIndex]) {
      this.addNewLineFeature(this.lineIndex);
    }
    this.#addPointOfLine(node);
    this.#addPointOfLine(neighborNode);
    this.addMiddlepointMarker(node?.id, neighborNode?.id);

    this.lineIndex++;
  };

  static #addPointOfLine = (node) => {
    if (!node?.id) {
      console.debug("Failed to create graph node - node is invalid", node);
      return;
    }
    this.graphData.features[this.lineIndex].geometry.coordinates.push(node.coordinate);
    this.graphData.features[this.lineIndex].properties.nodes.push(node.id);
  };

  static generateLines(node) {
    if (!node) {
      console.debug("Cannot generate line - invalid node: ", node);
      return;
    }
    if (this.nodes[node.id].isVisited) {
      return;
    }
    this.nodes[node.id].isVisited = true;
    [...node.neighbors]
      .filter((nid) => !this.nodes[nid]?.isVisited)
      .forEach((neighborId) => {
        this.populateGraphDataWithLines(node, this.nodes[neighborId]);
        this.generateLines(this.nodes[neighborId]);
      });
  }

  static generateMarkers() {
    Object.values(this.nodes).forEach((node) => {
      if (node.isTwin) {
        return;
      }
      let markerEl;
      const isTransition = this.isTransition(node.typeCode);
      if (isTransition) {
        let buildingEntranceExitTwinFeature;
        if (node?.typeCode === "building-entrance-exit") {
          buildingEntranceExitTwinFeature = Object.values(this.nodes)?.find(
            (f) => f?.portalGroupId === node?.portalGroupId && f?.id !== node?.id
          );
        }
        markerEl = customTransitionMarker({ markerId: node.id, className: "node-marker", typeCode: node.typeCode });
        const graphs = store.getters["CONTENT/graphs"];
        const portalGroupId = node?.portalGroupId;
        let nodesInTheSameGroup = [];

        if (portalGroupId) {
          nodesInTheSameGroup = graphs.filter((f) => f.properties.portalGroupId === portalGroupId);
        }

        if (node.neighbors?.size === 0 && nodesInTheSameGroup.length <= 1) {
          markerEl.classList.add("neighbor-marker-error");
        } else if (
          node.neighbors?.size === 0 ||
          nodesInTheSameGroup.length <= 1 ||
          buildingEntranceExitTwinFeature?.neighbors?.size === 0
        ) {
          markerEl.classList.add("neighbor-marker-warning");
        }
        let warningPopup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
          anchor: "bottom",
          className: "warning-popup"
        });
        warningPopup.setLngLat(node.coordinate);
        markerEl.addEventListener("click", () => {
          this.clearPopups();
          this.callbacks["graph.transitionClicked"].forEach((callback) => callback(node.id));
        });
        markerEl.addEventListener("mouseenter", () => {
          if (node.neighbors?.size === 0 && !node?.portalGroupId) {
            warningPopup?.setHTML("<p>- Not connected to any path</p><p>- Not connected to any transition</p>");
          } else if (node.neighbors?.size === 0) {
            warningPopup?.setHTML("<p>- Not connected to any path</p>");
          } else if (nodesInTheSameGroup.length <= 1) {
            warningPopup?.setHTML("<p>- Not connected to any transition</p>");
          }
          warningPopup?.addTo(this.map);
        });
        markerEl.addEventListener("mouseleave", () => {
          this.clearPopups();
        });
        this.transitionWarningPopups[node?.id] = warningPopup;
      } else {
        markerEl = customMarker({ markerId: node.id, className: "node-marker" });
        markerEl.addEventListener("contextmenu", (e) => this.markerRightClicked(e, markerEl, node.id));
      }
      markerEl.addEventListener("click", (e) => this.markerClick(e));
      this.addMarker(markerEl, node.coordinate, node.id, isTransition);
    });
  }

  static mouseMoveHandler(e) {
    if (!this.isEnabled) {
      return;
    }
    // cursor position
    const { lng, lat } = e.lngLat;
    if (this.dashedLineData.features[0].geometry.coordinates?.[0]?.length == 2) {
      this.setDashedLine(undefined, [lng, lat]);
    }

    // Snapping implementation
    let nodeIds = Object.keys(this.nodes);

    if (this.prevNodeId) {
      // filter selected node and its neighbors
      nodeIds = nodeIds.filter((id) => id !== this.prevNodeId && !this.nodes[this.prevNodeId].neighbors.has(id));
    }

    if (nodeIds.length < 1) {
      this.setSnapData();
      return;
    }

    // Get closest node to cursor nodes
    this.snapNode = this.getSnapNode([lng, lat]);

    if (!this.snapNode?.coordinate) {
      return;
    }

    // Using projected coordinates to find screen distance (irrelevant from zoom level)
    let projectedPt1 = this.map.project([lng, lat]);
    let projectedPt2 = this.map.project(this.snapNode.coordinate);
    let cursorAndSnapNodeDistance = this.distance([projectedPt1.x, projectedPt1.y], [projectedPt2.x, projectedPt2.y]);

    this.setSnapData(cursorAndSnapNodeDistance < this.cursorSnapDistance ? this.snapNode.coordinate : []);
  }

  static handleRightClick() {
    if (this.popups.length > 0) {
      this.clearPopups();
    }

    this.prevNodeId = undefined;
    this.setSnapData();
    this.setDashedLine();
  }

  static createNode(coord) {
    const newNodeId = Helpers.generateUuid();

    this.nodes[newNodeId] = {
      id: newNodeId,
      neighbors: new Set([this.prevNodeId].filter((x) => x)),
      coordinate: coord,
      typeCode: "graph-node"
    };

    if (this.prevNodeId) {
      this.nodes[this.prevNodeId].neighbors.add(newNodeId);
      this.nodes[newNodeId].neighbors.add(this.prevNodeId);
    }

    this.prevNodeId = newNodeId;

    this.setSnapData();

    return newNodeId;
  }

  static markerClick(e) {
    // Prevent adding new node under clicked node
    e.stopPropagation();

    const clickedNodeId = e.target.markerId;
    if (!this.isEnabled) {
      return;
    }

    this.clearPopups();

    if (this.prevNodeId === clickedNodeId) {
      // Clicked self
      this.setDashedLine();
      this.prevNodeId = undefined;
    } else if (this.prevNodeId) {
      // end line
      this.nodes[clickedNodeId].neighbors.add(this.prevNodeId);
      this.nodes[this.prevNodeId].neighbors.add(clickedNodeId);
      this.prevNodeId = undefined;
      this.setDashedLine();
      this.generateGraph();
      this.toggleMiddlePoints(true);
    } else {
      // node created
      this.prevNodeId = clickedNodeId;
      this.setDashedLine(this.nodes[clickedNodeId].coordinate);
    }

    console.debug("Clicked on node", this.nodes, this.graphData.features);
  }

  static markerRightClicked(e, markerEl, nodeId) {
    // Prevent getting clicked on map
    e.stopPropagation();
    // Prevent default right click of chrome
    e.preventDefault();

    this.clearPopups();
    const deleteButtonHandler = () => {
      this.setDashedLine();
      this.prevNodeId = undefined;
      // remove current node from neighbors
      this.nodes[nodeId]?.neighbors.forEach((neighborId) => {
        this.nodes[neighborId]?.neighbors?.delete(nodeId);
      });
      Object.values(this.nodes).forEach((node) => {
        if (node.portalNeighbors?.some((portal) => portal.fid === nodeId)) {
          this.nodes[node.id].portalNeighbors = node.portalNeighbors.filter((portal) => portal.fid !== nodeId);
        }
      });
      delete this.nodes[nodeId];

      this.clearPopups();
      this.setSnapData();
      markerEl.remove();
      this.callbacks["graph.delete"].forEach((callback) => callback(this.nodes, "deleted", nodeId));
      this.callbacks["graph.nodeSelected"].forEach((callback) => callback());

      this.generateGraph();
      this.toggleMiddlePoints(true);
    };

    const deleteButtonEl = createPopupButton({
      btnText: "Delete",
      clickFunc: deleteButtonHandler,
      btnColor: "#CC3247"
    });
    const btnsToAdd = [];

    if (this.isEnabled) {
      btnsToAdd.push(deleteButtonEl);
    }

    if (!btnsToAdd.length) {
      return;
    }
    const popupEl = createPopupElem(btnsToAdd);
    const popup = new maplibregl.Popup({ closeButton: false, className: "options-popup" })
      .setLngLat(this.nodes[nodeId].coordinate)
      .setDOMContent(popupEl)
      .addTo(this.map);

    this.popups.push(popup);
  }

  static addMarker(markerEl, coord, draggedNodeId, isTransition) {
    if (this.markers[draggedNodeId]) {
      console.debug("Duplicate marker");
      return;
    }
    const isDraggable = !isTransition;
    let marker = new maplibregl.Marker({ element: markerEl, draggable: isDraggable }).setLngLat(coord).addTo(this.map);

    if (!isTransition) {
      marker.on("drag", (e) => {
        const { lng, lat } = e.target._lngLat;

        this.nodes[draggedNodeId].coordinate = [lng, lat];

        this.graphData.features.forEach((line) => {
          line.properties.nodes.forEach((nodeId, i) => {
            if (nodeId == draggedNodeId) {
              line.geometry.coordinates[i] = [lng, lat];
            }
          });
        });
        this.toggleMiddlePoints(false);
        this.renderGraph();
        this.setSnapData();
      });

      marker.on("dragstart", () => {
        this.prevNodeId = undefined;
        this.setDashedLine();
      });

      marker.on("dragend", () => {
        // Update middlepoint marker positions
        this.updateMiddlepointPositions(draggedNodeId);

        this.toggleMiddlePoints(true);
        this.callbacks["graph.update"].forEach((callback) => callback(this.nodes, "updated"));
      });
    }

    this.markers[draggedNodeId] = marker;
    this.callbacks["graph.create"].forEach((callback) => callback(this.nodes, "created", draggedNodeId));
  }

  /**
   * Shows and hides middlePoints
   */
  static toggleMiddlePoints(shouldShow) {
    // Hide middlepoint markers
    let mdMarkers = document.getElementsByClassName("middlepoint-marker");
    [...mdMarkers].forEach((mdPointMarker) => {
      if (shouldShow) {
        mdPointMarker.style.visibility = "visible";
      } else {
        mdPointMarker.style.visibility = "hidden";
        this.clearPopups();
      }
    });
  }

  /**
   * Middlepoint is orange dot between 2 nodes which opens popup on click
   */
  static addMiddlepointMarker(nodeId1, nodeId2) {
    if (!nodeId1 || !nodeId2) {
      return;
    }
    let middlepointId = `${nodeId1}__${nodeId2}`;
    let markerEl = customMarker({ markerId: middlepointId, className: "middlepoint-marker" });
    let coord = this.getMiddleCoordinate(nodeId1, nodeId2);
    let marker = new maplibregl.Marker({ element: markerEl, draggable: false }).setLngLat(coord).addTo(this.map);

    // Save all middlepointMarkers
    this.middlepointMarkers[`${nodeId1}__${nodeId2}`] = marker;

    markerEl.addEventListener("click", (e) => this.middlepointClickHandler(e, nodeId1, nodeId2));
  }

  static middlepointClickHandler(e, nodeId1, nodeId2) {
    // Prevent adding new node
    e.stopPropagation();

    if (!this.isEnabled || this.prevNodeId) {
      // prevNodeId is active during line creation
      return;
    }

    this.clearPopups();
    let { lng, lat } = this.middlepointMarkers[`${nodeId1}__${nodeId2}`]._lngLat;

    let addButtonEl = createPopupButton({
      btnText: "Add Middle Point",
      clickFunc: () => {
        // Add node between 2 nodes
        this.nodes[nodeId1].neighbors.delete(nodeId2);
        this.nodes[nodeId2].neighbors.delete(nodeId1);
        const createdNodeId = this.createNode([lng, lat]);
        this.nodes[createdNodeId].neighbors.add(nodeId1);
        this.nodes[createdNodeId].neighbors.add(nodeId2);
        this.nodes[nodeId1].neighbors.add(createdNodeId);
        this.nodes[nodeId2].neighbors.add(createdNodeId);

        this.prevNodeId = undefined;
        this.generateGraph();
        this.toggleMiddlePoints(true);
      }
    });
    let popupEl = createPopupElem([addButtonEl]);
    const popup = new maplibregl.Popup({ closeButton: false, className: "options-popup" })
      .setLngLat([lng, lat])
      .setDOMContent(popupEl)
      .addTo(this.map);

    this.popups.push(popup);
  }

  /**
   * When node is moved, middlepoints on left/right of it should also move
   */
  static updateMiddlepointPositions(nodeId) {
    let mdpointIds = [];
    this.nodes[nodeId].neighbors.forEach((neighborId) => {
      mdpointIds.push(`${neighborId}__${nodeId}`);
      mdpointIds.push(`${nodeId}__${neighborId}`);
    });
    mdpointIds.forEach((mdpointId) => {
      if (this.middlepointMarkers[mdpointId]) {
        let [nodeId1, nodeId2] = mdpointId.split("__");
        this.middlepointMarkers[mdpointId].setLngLat(this.getMiddleCoordinate(nodeId1, nodeId2));
      }
    });
  }

  // enable/disable
  static toggle(options) {
    if (!this.map) {
      return; // setup not called yet. So we don't need toggle
    }
    this.isEnabled = options?.shouldEnable ?? !this.isEnabled;
    this.toggleMiddlePoints(this.isEnabled);

    if (this.isEnabled) {
      this.map.off("click", this.mapClick);
      this.map.off("mousemove", this.mouseMoveHandler);
      this.map.off("contextmenu", this.handleRightClick);
      this.map.on("click", this.mapClick);
      this.map.on("mousemove", this.mouseMoveHandler);
      this.map.on("contextmenu", this.handleRightClick);

      Object.values(this.markers).forEach((marker) => {
        if (!marker._element.classList.contains("transition-node")) {
          marker.setDraggable(true);
        }
      });
      document.querySelectorAll(".node-marker")?.forEach((marker) => {
        marker.classList.remove("node-marker-hidden");
      });
    } else {
      this.prevNodeId = undefined;
      this.setSnapData();
      this.setDashedLine();
      this.map.off("click", this.mapClick);
      this.map.off("mousemove", this.mouseMoveHandler);
      this.map.off("contextmenu", this.handleRightClick);

      document.getElementsByClassName("map-wrapper")?.[0]?.classList?.remove("crosshair");
      Object.values(this.markers).forEach((marker) => {
        marker.setDraggable(false);
      });
      document.querySelectorAll(".node-marker")?.forEach((marker) => {
        marker.classList.add("node-marker-hidden");
      });
    }
  }

  // Updates lines
  static renderGraph() {
    const graphSource = this.map?.getSource(graphLayer.source);
    if (graphSource) {
      graphSource.setData(this.graphData);
    }
  }

  // Get closest node which is not prevNode or its neighbors
  static getSnapNode(cursorPos) {
    let turfCursorPos = point(cursorPos);

    // Remove self and neighbors from snapping node list
    const filteredNodes = Object.values(this.nodes).filter(
      (node) => this.prevNodeId !== node.id && !this.nodes[this.prevNodeId]?.neighbors?.has(node.id)
    );

    return filteredNodes.reduce(
      (closestNode, node) => {
        if (!node.coordinate) {
          // clicked another marker. won't add since marker already exists
          return closestNode;
        }
        const turfPoint = point(node.coordinate);
        const distanceToCursor = distance(turfCursorPos, turfPoint);
        return closestNode.dist < distanceToCursor ? closestNode : { node, dist: distanceToCursor };
      },
      {
        node: undefined,
        dist: Infinity
      }
    ).node;
  }

  // Dashed line is composed of 2 points. First one is starting point, second one is usually used for cursor position
  static setDashedLine(coord1, coord2) {
    if (!coord1 && !coord2) {
      // remove dashed line
      this.dashedLineData.features[0].geometry.coordinates = [];

      document.getElementsByClassName("map-wrapper")?.[0]?.classList?.remove("crosshair");
      Object.values(this.markers).forEach((marker) => {
        if (!marker._element.classList.contains("transition-node")) {
          marker.setDraggable(true);
        }
      });
    } else if (!coord2) {
      // set start pos
      this.dashedLineData.features[0].geometry.coordinates[0] = coord1;

      document.getElementsByClassName("map-wrapper")[0].classList.add("crosshair");
      Object.values(this.markers).forEach((marker) => {
        marker.setDraggable(false);
      });
    } else if (!coord1) {
      // set cursor pos
      this.dashedLineData.features[0].geometry.coordinates[1] = coord2;
    }
    if (this.map.getSource(dashedLineLayer.source)) {
      this.map.getSource(dashedLineLayer.source).setData(this.dashedLineData);
    }
  }

  static distance(coord1, coord2) {
    return Math.sqrt((coord1[0] - coord2[0]) ** 2 + (coord1[1] - coord2[1]) ** 2);
  }

  static addNewLineFeature() {
    let emptyFeature = {
      type: "Feature",
      properties: {
        nodes: []
      },
      geometry: {
        type: "LineString",
        coordinates: []
      }
    };
    this.graphData.features.push(emptyFeature);
  }

  static getMiddleCoordinate(nodeId1, nodeId2) {
    let coord1 = this.nodes[nodeId1].coordinate;
    let coord2 = this.nodes[nodeId2].coordinate;
    return [(coord1[0] + coord2[0]) / 2, (coord1[1] + coord2[1]) / 2];
  }

  // Sets snap data and renders under marker. If no parameter provided, removes snap
  static setSnapData(coordinates) {
    if (!coordinates) {
      this.snapData.features[0].geometry.coordinates = [];
    } else {
      this.snapData.features[0].geometry.coordinates = coordinates;
    }
    if (this.map.getSource(snappedNodeLayer.source)) {
      this.map.getSource(snappedNodeLayer.source).setData(this.snapData);
    }
  }

  // Converts neighbours field from Set to Array
  static exportNode(node) {
    return {
      ...node,
      neighbors: [...node.neighbors]
    };
  }

  static createNodesFromFeatures(features, isOutdoor = false) {
    features.forEach((feature) => {
      let neighbors = [];
      if (feature.properties.neighbors) {
        neighbors = [...feature.properties.neighbors].map((n) => n.fid);
      }

      const node = {
        id: feature.properties.fid,
        coordinate: feature.geometry.coordinates,
        neighbors: new Set(neighbors),
        isVisited: false,
        portalNeighbors: feature.properties.portalNeighbors || [],
        portalGroupId: feature.properties.portalGroupId,
        typeCode: feature.properties.typeCode
      };
      if (feature.properties.typeCode === "building-entrance-exit") {
        if (isOutdoor) {
          node.isTwin = feature.properties.bid !== undefined && feature.properties.lvl !== undefined;
        } else {
          node.isTwin = feature.properties.bid === undefined && feature.properties.lvl === undefined;
        }
      }
      // Custom portal fields
      if (feature.properties.travelTime !== undefined) {
        node.travelTime = Number(feature.properties.travelTime) || 0;
      }
      if (feature.properties.isAccessible !== undefined) {
        node.isAccessible = feature.properties.isAccessible;
      }
      if (feature.properties.isComfortable !== undefined) {
        node.isComfortable = feature.properties.isComfortable;
      }
      if (feature.properties.name !== undefined) {
        node.name = feature.properties.name;
      }
      this.nodes[node.id] = node;
    });
    this.prevNodeId = undefined;
  }

  static exportAsGeoJson() {
    let geoJson = {
      type: "FeatureCollection",
      features: []
    };
    geoJson.features = Object.values(this.nodes)
      .filter((node) => !node.isTwin)
      .map((node) => {
        return this.nodeToFeature(node);
      });
    return geoJson;
  }

  static nodeToFeature(node) {
    if (!node) {
      console.debug("Cannot convert node to feature - selected node does not exist", node);
      return;
    }
    node = this.exportNode(node);
    const neighbors = node.neighbors.map((neighbor) => {
      return { fid: neighbor, speed: 1 };
    });

    let copyNode = { ...node };
    delete copyNode.id;
    delete copyNode.coordinate;
    delete copyNode.isVisited;
    delete copyNode.isTwin;

    return {
      type: "Feature",
      properties: {
        ...copyNode,
        sid: this.sid,
        bid: this.bid,
        lvl: this.lvl,
        fid: node.id,
        name: node.name,
        typeCode: node.typeCode || "graph-node",
        neighbors: neighbors,
        portalNeighbors: node.portalNeighbors || [],
        portalGroupId: node.portalGroupId
      },
      geometry: {
        type: "Point",
        coordinates: [Number(node.coordinate[0].toFixed(8)), Number(node.coordinate[1].toFixed(8))]
      }
    };
  }

  static clearPopups() {
    const transitionPopups = document.getElementsByClassName("warning-popup");
    if (transitionPopups.length) {
      transitionPopups[0].remove();
    }

    this.popups?.forEach((p) => p.remove());
    this.popups = [];
  }

  // Update properties of portal nodes
  static updateSelectedContent(updatedContent) {
    if (!updatedContent) {
      return;
    }
    // Since custom property names are same at node and content
    let node = this.nodes[updatedContent.properties.fid];
    if (typeof node !== "object") {
      console.debug("Not updating selected content - not type of object");
      return;
    }
    Object.keys(node).forEach((property) => {
      if (updatedContent.properties[property] !== undefined && property !== "neighbors") {
        node[property] = updatedContent.properties[property];
      }
    });
  }

  static isTransition(typeCode) {
    return typeCode !== "graph-node";
  }
}
