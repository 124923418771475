<template>
  <div>
    <v-text-field
      ref="textInput"
      v-model.trim="url"
      :label="$t(`${translationPath}label`)"
      :rules="[shouldOverride ? true : rules.website]"
      hide-details="auto"
      outlined
      dense
      :disabled="shouldOverride || isReadOnly"
      @keydown="setFormDirty"
    >
      <template #append>
        <PtrIcon
          v-if="value?.length && !isReadOnly"
          class="mr-2"
          icon="cancel-circle"
          width="18"
          height="18"
          style="cursor: pointer"
          @click.native="resetValue"
        />
      </template>
    </v-text-field>
    <div class="d-flex mb-n4">
      <v-checkbox
        v-model="shouldOverride"
        :label="$t('contents.mapDesigner.poiProperties.overridable.overrideMode')"
        :disabled="isReadOnly"
        @change="setFormDirty"
      />
      <HelpComponent
        class="mt-1"
        :content="{
          header: $t('contents.mapDesigner.poiProperties.overridable.helpHeader'),
          description: $t('contents.mapDesigner.poiProperties.overridable.helpDescription')
        }"
      ></HelpComponent>
    </div>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import HelpComponent from "@/components/shared/HelpComponent.vue";
import ValidationHelpers from "@/helpers/ValidationHelpers";

export default {
  components: { PtrIcon, HelpComponent },
  props: {
    value: Object,
    propertyKey: String,
    isReadOnly: { type: Boolean, default: false }
  },
  data: () => ({
    overridableTranslationPath: "contents.mapDesigner.poiProperties.overridable.",
    url: undefined,
    shouldOverride: undefined
  }),
  computed: {
    rules() {
      return {
        website: (value) => {
          if (this.shouldOverride) {
            return true;
          }
          if (value === undefined || value === "") {
            return this.$t("contents.mapDesigner.propertyRequired", { propertyName: "Url" });
          }
          return (
            ValidationHelpers.isUrlValid(value) || this.$t("contents.mapDesigner.notValid", { propertyName: "Url" })
          );
        }
      };
    },
    translationPath() {
      return `contents.mapDesigner.poiProperties.overridable.${this.propertyKey}.`;
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.url = this.value?.value || "";
        this.shouldOverride = this.value?.shouldOverride;
      }
    },
    url() {
      this.$emit("input", { value: this.url, shouldOverride: this.shouldOverride });
    },
    shouldOverride() {
      if (!this.shouldOverride) {
        this.$refs.textInput.validate();
      }
      this.$emit("input", { value: this.url, shouldOverride: this.shouldOverride });
    }
  },
  created() {
    let url = "";
    // To handle when the previously saved data is invalid
    if (typeof this.value?.value === "string") {
      url = this.value?.value;
    }
    this.url = url;
    this.shouldOverride = this.value?.shouldOverride;
  },
  methods: {
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--checkbox {
  margin-top: 0;
  margin-left: 8px;
}

::v-deep .v-input--is-disabled {
  * {
    color: var(--v-neutral-lighten2) !important;
  }
}

::v-deep .v-btn__content {
  padding-top: 4px;
}
</style>
